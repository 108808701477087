<template>
  <figure class="image is-48x48">
    <el-upload
      class="avatar-uploader"
      action
      :http-request="firebaseUpload"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
    >
      <div v-if="condo.profile">
        <el-image
          class="avatar"
          style="height: 48px"
          :src="condo.profile"
        ></el-image>
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </figure>
</template>
<script>
import { mapGetters } from "vuex";
import { db, storage } from "@/firebase";

export default {
  name: "CondoProfile",
  data() {
    return {
      imageUrl: "",
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      const isFileType =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isFileType) {
        this.$message("Image must be JPG/PNG format");
      }
      if (!isLt2M) {
        this.$message("Please upload image size less than 2Mb");
      }
      return isFileType && isLt2M;
    },
    firebaseUpload(payload) {
      let _this = this;
      storage
        .ref("condos")
        .child(this.condo.place_id + "/profile.jpg")
        .put(payload.file)
        .then(function(snapshot) {
          snapshot.ref.getDownloadURL().then(function(url) {
            const data = {
              profile: url,
            };
            db.ref("condos")
              .child(_this.condo.place_id)
              .update(data, (error) => {
                if (error) {
                  _this.$message("Please try again");
                } else {
                  _this.$message.success(
                    "Updated successfully, Thanks for your contribution"
                  );
                }
              });
          });
          _this.$message.success("Image uploaded");
        });

      this.dialogVisible = false;
    },
  },
  computed: {
    ...mapGetters(["condo"]),
    console: () => console,
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  background: #d3d3d3;
  border: 1px solid #f7f7f7;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border: 1px solid rgb(34, 196, 136);
}
.avatar-uploader-icon {
  font-size: 18px;
  color: #8c939d;
  width: 46px;
  height: 46px;
  line-height: 48px;
  text-align: center;
}
.avatar {
  border-radius: 50%;
  display: block;
}
.image img {
  height: 100%;
}
</style>
