<template>
  <el-row class="">
    <el-col :lg="24">
      <nav class="level mx-2 mb-4 is-mobile">
        <div class="level-left">
          <!-- <div class="title is-4">Editors' Choice</div> -->
          <div class="title is-5">Posts & Reviews</div>
        </div>
        <div class="level-right is-size-6-7">
          <a @click="updateDialogVisible({ type: 'review', isVisible: true })"
            >see more
            <!-- <i class="fas fa-window-maximize is-size-7"></i> -->
          </a>
        </div>
      </nav>

      <div class="scrolling-wrapper-review">
        <div class="review-button" @click="postReviewDialogVisible = true">
          <i class="fas fa-plus"></i>
          <div class="button-text">
            Post
          </div>
        </div>

        <!-- ReviewCard -->
        <CondoReviewCard
          v-for="(review, index) in reviews"
          :key="index"
          :review="review"
          cardWidth="202px"
          cardHeight="173px"
        />

        <div
          v-if="reviews.length > 0"
          class="card review-blank"
          :style="{
            width: '250px',
            height: '173px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              Help the community
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some blog posts.
            </p>
          </a>
        </div>

        <div
          v-if="reviews.length == 0"
          class="card review-blank"
          :style="{
            width: '250px',
            height: '173px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              No Posts Yet
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some blog posts.
            </p>
          </a>
        </div>
      </div>

      <!-- PostDialog -->
      <el-dialog
        v-if="getLoginStatus"
        title="Post Property Content"
        :visible.sync="postReviewDialogVisible"
        :width="screenWidth"
        top="15vh"
        center
      >
        <el-form :model="form" ref="review-form">
          <el-form-item
            prop="title"
            :rules="[{ required: true, message: 'title is required' }]"
          >
            <el-input
              placeholder="What's your review title?"
              size="medium"
              v-model="form.title"
              type="text"
              maxlength="80"
              show-word-limit
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="url"
            :rules="[
              { required: true, message: 'url is required' },
              { type: 'url', message: 'Invalid URL' },
            ]"
          >
            <el-input
              placeholder="URL link to the review site (https:// website url)"
              v-model="form.url"
            >
              <template slot="prepend"><i class="fas fa-globe"></i></template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-upload
          ref="review-upload"
          class="review-upload"
          action
          drag
          list-type="picture-card"
          :file-list="fileList"
          :http-request="firebaseUpload"
          :auto-upload="false"
          :on-change="onChange"
        >
          <i class="el-icon-upload is-size-3 has-text-info my-2"></i>
          <div class="el-upload__text">
            Drop an image here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png file with a size less than 3Mb
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <button
            v-if="
              fileList.length == 1 &&
                form.title != '' &&
                form.url.includes('http')
            "
            class="button is-primary is-fullwidth"
            @click="
              submitUpload();
              postReviewDialogVisible = false;
            "
          >
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
          <button v-else class="button is-primary is-fullwidth" disabled>
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
        </span>
      </el-dialog>
      <!-- End PostDialod -->

      <!-- LoginDialog -->
      <LoginDialog
        :dialogVisible="postReviewDialogVisible"
        v-on:disable-loginDialog="postReviewDialogVisible = false"
      />
      <!-- End LoginDialog -->

      <CondoReviewsDialog
        :reviewsDialogVisible="reviewsDialogVisible"
        v-on:disable-reviewDialog="
          updateDialogVisible({ type: 'review', isVisible: false })
        "
      />
    </el-col>
  </el-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { db, storage } from "@/firebase";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import CondoReviewCard from "./CondoReviewCard";
import CondoReviewsDialog from "./CondoReviewsDialog";
import LoginDialog from "@/components/users/LoginDialog";

// import firebase from "firebase/app";
export default {
  name: "CondoReviews",
  components: {
    CondoReviewCard,
    CondoReviewsDialog,
    LoginDialog,
  },
  data() {
    return this.init();
  },
  beforeMount: function() {
    this.queryReviews();
  },

  watch: {
    $route() {
      this.queryReviews();
    },
    postReviewDialogVisible: function() {
      this.fileList = [];
    },
  },
  methods: {
    ...mapActions([
      "signUserInGoogle",
      "updateScreenWidth",
      "updateDialogVisible",
    ]),
    init() {
      return {
        width: String,
        reviews: [],
        fileList: [],
        postReviewDialogVisible: false,
        form: {
          title: "",
          url: "",
        },
      };
    },
    queryReviews() {
      db.ref("condo_reviews")
        .child(this.$route.params.id)
        .orderByChild("timestamp")
        .limitToLast(10)
        .on("value", (snapshot) => {
          this.reviews = [];
          var docs = snapshot.val();
          if (docs) {
            for (const value of Object.values(docs)) {
              this.reviews.push(value);
            }
            // (b,a) = descending order
            this.reviews = this.reviews.sort((b, a) => a.likes - b.likes);
          }
        });
      // https://stackoverflow.com/questions/59758751/filter-and-sort-an-array-of-objects-in-vue
    },

    submitUpload() {
      this.$refs["review-form"].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$refs["review-upload"].submit();
        } else {
          this.$message("Invalid input, please check once again");
          return false;
        }
      });
    },
    firebaseUpload(payload) {
      if (this.getLoginStatus) {
        // console.log(payload);
        const ID = uuidv4();
        var original_url = "";
        let filename = ID + ".jpg";
        let filename_thumbnail = ID + "_500x500.jpg";
        let storage_child =
          "condos/" +
          this.condo.place_id +
          "/reviews/" +
          this.getUser.user.uid +
          "/";

        let _this = this;
        storage
          .ref()
          .child(storage_child + filename)
          .put(payload.file)
          .then(function(snapshot) {
            _this.$message.success("Image uploaded");
            snapshot.ref.getDownloadURL().then(function(url) {
              original_url = url;
              var data = {
                review_id: ID,
                user_id: _this.getUser.user.uid,
                condo_id: _this.condo.place_id,
                datetime: new Date(),
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                filename: filename,
                filename_thumbnail: filename_thumbnail,
                photo_url: url,
                photo_url_orginal: original_url,
                title: _this.form.title,
                review_url: _this.form.url,
                likes: 0,
              };

              db.ref("condo_reviews")
                .child(_this.condo.place_id + "/" + ID)
                .update(data, (error) => {
                  if (error) {
                    _this.$message("An error occurred, please try again");
                  } else {
                    _this.$message.success("Posted successfully");
                    _this.$refs["review-form"].resetFields();
                  }
                });
              // end db.ref
            });
            // end snapshot.ref

            setTimeout(() => {
              storage
                .ref()
                .child(storage_child + filename_thumbnail)
                .getDownloadURL()
                .then(function(url) {
                  db.ref("condo_reviews")
                    .child(_this.condo.place_id + "/" + ID)
                    .update({
                      photo_url: url,
                    });
                });
              // end storage.ref
            }, 3000);
            // end timeout
          })
          .catch(() => {
            this.$message("Unable to create post");
          });
      }
    },
    onChange(file, fileList) {
      const fileType = file.raw.type;
      const isFileType = fileType === "image/jpeg" || fileType === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isFileType) {
        this.$message("Image must be JPG/PNG format");
      }
      if (!isLt2M) {
        this.$message("Please upload image size less than 3Mb");
      }

      if (isFileType && isLt2M) {
        this.fileList = [file];
      } else {
        fileList.pop();
      }
      return isFileType && isLt2M;
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "condo",
      "getLoginStatus",
      "screenWidth",
      "reviewsDialogVisible",
    ]),
    console: () => console,
  },
};
</script>

<style lang="scss">
.review-blank {
  background-image: url("~@/assets/review_blank.png");
}

.review-blank:hover {
  box-shadow: 3px 3px 20px rgb(126, 151, 173, 0.3) !important;
}
</style>
