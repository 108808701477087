<template>
  <div>
    <el-dialog
      title="Edit Property Info"
      :visible.sync="show"
      center
      :width="infoWidth"
      @close="onClose"
    >
      <el-form
        label-width="125px"
        :model="form"
        ref="form"
        class="demo-ruleForm"
      >
        <el-form-item
          label="Property name"
          prop="name"
          :rules="[{ required: true, message: 'Property name is required' }]"
        >
          <el-input
            size="medium"
            v-model="form.name"
            type="text"
            maxlength="40"
            show-word-limit
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Developer" prop="developer" :rules="[]">
          <el-input
            size="medium"
            v-model="form.developer"
            type="text"
            maxlength="20"
            show-word-limit
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Stories"
          prop="stories"
          :rules="[
            { type: 'number', message: 'stories must be a number' },
            {
              type: 'number',
              max: 100,
              message: 'stories must be lower than 100',
            },
          ]"
        >
          <el-input
            size="medium"
            v-model.number="form.stories"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="Units"
          prop="units"
          :rules="[
            { type: 'number', message: 'units must be a number' },
            {
              type: 'number',
              max: 10000,
              message: 'units must be lower than 10,000',
            },
          ]"
        >
          <el-input
            size="medium"
            v-model.number="form.units"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="Parking %"
          prop="parking"
          :rules="[
            { type: 'number', message: 'parking must be a number' },
            {
              type: 'number',
              max: 100,
              message: 'parking % cannot be higher than 100',
            },
          ]"
        >
          <el-input
            size="medium"
            v-model.number="form.parking"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->

        <el-form-item
          label="Year Built"
          prop="built"
          :rules="[
            { type: 'number', message: 'Year must be a number' },
            {
              type: 'number',
              min: 1990,
              max: 2040,
              message: 'Invalid year range',
            },
          ]"
        >
          <el-input
            size="medium"
            v-model.number="form.built"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="฿Price/sq.m."
          prop="price"
          :rules="[
            { type: 'number', message: 'price must be a number' },
            {
              type: 'number',
              min: 10000,
              max: 1000000,
              message: 'Price should be in range of ฿1,000 to ฿1,000,000',
            },
          ]"
        >
          <ElCurrencyInput size="medium" v-model.number="form.price" />
        </el-form-item>
        <el-form-item
          label="Website"
          prop="website"
          :rules="[{ type: 'url', message: 'Invalid URL' }]"
        >
          <el-input
            size="medium"
            v-model="form.website"
            type="url"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="button is-fullwidth is-size-6-7"
          @click="submitForm('form')"
        >
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ElCurrencyInput from "@/components/utils/ElCurrencyInput";
import { db } from "@/firebase";
export default {
  name: "InfoEditor",
  props: ["dialogVisible"],
  components: {
    ElCurrencyInput,
  },
  data() {
    return {
      infoWidth: "40%",
      show: false,
      form: {
        name: null,
        developer: null,
        stories: null,
        units: null,
        parking: null,
        built: null,
        price: null,
        website: null,
      },
    };
  },
  created() {
    // https://codepen.io/sethdavis512/pen/EvNKWw
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    condo: function() {
      Object.assign(this.$data.form, this.initForm());
    },
    dialogVisible: function() {
      this.show = this.dialogVisible;
    },
  },
  mounted() {
    Object.assign(this.$data.form, this.initForm());
  },
  methods: {
    handleResize() {
      var width = window.innerWidth;
      if (width > 1200) {
        this.infoWidth = "40%";
      } else {
        this.infoWidth =
          Math.min(String(160 - parseInt(width / 10)), 100) + "%";
        // console.log(this.infoWidth);
      }
    },
    initForm() {
      var info = this.condo.info;
      return {
        name: info ? info.name : this.condo.name,
        developer: info ? info.developer || "" : null,
        stories: info ? info.stories || null : null,
        units: info ? info.units || null : null,
        parking: info ? info.parking || null : null,
        built: info ? info.built || null : null,
        price: info ? info.price || null : null,
        website: info ? info.website || "" : null,
      };
    },
    onClose() {
      this.$emit("disable-infoDialog");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            info: this.form,
          };
          // console.log(data);
          db.ref("condos")
            .child(this.condo.place_id)
            .update(data, (error) => {
              if (error) {
                this.$message("Please try again");
              } else {
                this.$message.success(
                  "Updated successfully, Thanks for your contribution"
                );
              }
            });
        } else {
          this.$message("Invalid input");
          return false;
        }
      });
      this.onClose();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    ...mapGetters(["condo"]),
    console: () => console,
  },
};
</script>
<style lang=""></style>
