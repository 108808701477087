<template>
  <div v-if="condo" id="condoProfile" class="pt-4">
    <br />
    <!-- <br /> -->
    <!-- <br /> -->
    <el-container class="mt-3" style="height: 96%; border: 1px solid #eee">
      <el-container>
        <Aside />
        <el-main class="px-0 pt-5 mt-2">
          <!-- Main Banner -->
          <div class="mx-5">
            <!-- Header -->
            <!-- Breadcrumb -->
            <div class="level is-mobile mb-0">
              <!-- Left -->
              <div class="level-left">
                <el-breadcrumb separator="/" class="mb-5">
                  <el-breadcrumb-item
                    class="is-size-6-7"
                    v-if="activeLine == '' || activeStation == ''"
                    :to="{ path: '/' }"
                  >
                    Home
                  </el-breadcrumb-item>
                  <el-breadcrumb-item
                    class="is-size-6-7"
                    v-else
                    :to="{
                      path: '/stations/' + activeLine + '/' + activeStation,
                    }"
                  >
                    <i class="fas fa-chevron-left mr-1"></i> Map
                  </el-breadcrumb-item>
                  <el-breadcrumb-item class="is-size-6-7"
                    >Property Overview</el-breadcrumb-item
                  >
                </el-breadcrumb>
              </div>

              <!-- Right Options -->
              <div class="level-right pb-5" style=" vertical-align: text-top;">
                <!-- InfoEditor -->
                <el-dropdown
                  v-if="getLoginStatus"
                  @command="handleInfoCommand"
                  trigger="click"
                >
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-h has-text-info"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit" icon="el-icon-edit">
                      Edit
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <InfoEditor
                  v-if="getLoginStatus"
                  :dialogVisible="infoDialogVisible"
                  v-on:disable-infoDialog="infoDialogVisible = false"
                />
              </div>
            </div>
            <!-- End Header -->

            <!-- Media title -->
            <el-row class="mb-5">
              <el-col :span="24">
                <div class="media pt-3">
                  <div class="media-left">
                    <CondoProfile />
                  </div>
                  <!-- LargeScreen -->
                  <div class="media-content" v-if="this.screenType == 'md'">
                    <div class="level is-size-7">
                      <!-- Left side -->
                      <div class="level-left">
                        <div class="level-item has-text-left">
                          <span>
                            <div v-if="condo.info">
                              <p class="title is-4 mb-5 ellipsis">
                                {{ condo.info.name }}
                              </p>
                              <p class="subtitle is-size-6-7 pb-1">
                                Developed by<a href="" class="has-text-dark">
                                  {{ condo.info.developer }}
                                </a>
                              </p>
                            </div>
                            <div v-else>
                              <p class="title is-4 mb-5 ellipsis">
                                {{ condo.name }}
                              </p>
                              <p class="subtitle is-size-6-7 pb-1">
                                Developed by
                                <a href="" class="has-text-dark">
                                  {{ condo.developer }}
                                </a>
                              </p>
                            </div>
                          </span>
                        </div>
                      </div>

                      <!-- Right side -->
                      <div class="level-right">
                        <div class="level-item has-text-right pt-1">
                          <p class="mr-4" style="line-height: 1.7;">
                            <i class="fa fa-road has-text-info" />
                            <span class="subtitle is-6">
                              {{ stationDistance }}m
                            </span>
                            <br />

                            <span
                              class="has-text-info is-size-6-7 ellipsis-hidden"
                              >to
                              {{
                                station
                                  ? station.line + " " + station.name_en
                                  : "station"
                              }}
                            </span>
                          </p>
                          <p style="line-height: 1.7;">
                            <i class="fa fa-star has-text-primary" />
                            <span class="subtitle is-6">
                              {{ condo.rating }} ({{
                                condo.user_ratings_total
                              }}) </span
                            ><br />
                            <span class="has-text-info is-size-6-7">
                              on Google map

                              <a
                                class="has-text-info is-size-7"
                                :href="getMapURL()"
                                target="_blank"
                              >
                                <i
                                  class="fa fa-external-link-alt has-text-info"
                                />
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Mobile -->
                  <div v-else class="media-content">
                    <div v-if="condo.info">
                      <p class="title is-5 mb-5">
                        {{ condo.info.name }}
                      </p>
                      <p class="subtitle is-size-6-7 pb-1">
                        Developed by<a href="" class="has-text-dark">
                          {{ condo.info.developer }}
                        </a>
                      </p>
                    </div>
                    <div v-else>
                      <p class="title is-5 mb-5 ellipsis">
                        {{ condo.name }}
                      </p>
                      <p class="subtitle is-size-6-7 pb-1">
                        Developed by
                        <a href="" class="has-text-dark">
                          {{ condo.developer }}
                        </a>
                      </p>
                    </div>
                    <div
                      v-if="this.screenType != 'md'"
                      class="level is-mobile mt-3"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <p class="mr-2" style="line-height: 1.7;">
                            <i class="fa fa-road has-text-info" />
                            <span class="subtitle is-7">
                              {{ stationDistance }}m
                            </span>
                            <br />
                            <span
                              class="has-text-info is-size-7 ellipsis-hidden"
                              >to {{ station ? station.line : "" }} station
                            </span>
                          </p>
                        </div>
                        <div class="level-item">
                          <p style="line-height: 1.7;">
                            <i class="fa fa-star has-text-primary" />
                            <span class="subtitle is-7">
                              {{ condo.rating }} ({{
                                condo.user_ratings_total
                              }})
                            </span>
                            <br />
                            <span class="has-text-info is-size-7">
                              on Google map
                              <a
                                class="has-text-info is-size-7"
                                :href="getMapURL()"
                                target="_blank"
                              >
                                <i
                                  class="fa fa-external-link-alt has-text-info"
                                />
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- End GoogleMapRating  -->
                  </div>
                  <!-- End Media Content -->
                </div>
                <!-- End Media -->
              </el-col>
            </el-row>
            <!-- End Media title -->

            <!-- CondoInfo -->
            <el-row class="mb-3" :gutter="20">
              <!-- Center -->
              <el-col class="mb-4" :sm="24" :md="24" :lg="16">
                <PhotoGrid :condoId="condoId" />
              </el-col>
              <!-- Right aside -->
              <el-col class="mb-3" :sm="24" :md="12" :lg="8">
                <CondoInfo :info="condo.info" />
              </el-col>
              <!-- See all photos -->
              <el-col class="mb-3" :sm="24" :md="12" :lg="4">
                <el-button
                  type="primary"
                  icon="el-icon-picture"
                  class="button is-fullwidth"
                  @click="
                    updateDialogVisible({ type: 'gallery', isVisible: true })
                  "
                >
                  See all photos
                </el-button>
              </el-col>
            </el-row>
            <!-- End CondoInfo -->
          </div>
          <!-- End Main Banner -->

          <!-- Condo Components -->
          <!-- Row 1 -->
          <el-row class="mb-3 pl-5" :gutter="0">
            <!-- Right -->
            <el-col class="mb-4 pr-5" :sm="24" :md="24" :lg="16">
              <el-row class="condo-info pt-5" style="">
                <el-col class="mr-2" :sm="24" :md="24" :lg="24">
                  <CondoReviews />
                </el-col>
                <el-col class="pb-3" :sm="24" :md="24" :lg="24">
                  <CondoVideos />
                </el-col>
              </el-row>
            </el-col>
            <!-- Left -->
            <el-col class="pr-5" :sm="24" :md="24" :lg="8">
              <CondoNearby class="condo-info pt-5 pb-0" />
            </el-col>
          </el-row>
          <!-- Row 2 -->
          <el-row class="mb-2 px-5" :gutter="0">
            <el-col class="pt-4" :sm="24" :md="24" :lg="24">
              <CondoBuildings />
            </el-col>
          </el-row>
          <!-- Row 3 -->
          <el-row class="mb-3 px-5" :gutter="0">
            <el-col :sm="24" :md="24" :lg="24">
              <CondoLayouts />
            </el-col>
          </el-row>

          <!-- End Condo Components -->
        </el-main>
      </el-container>
    </el-container>
    <!-- Dialogs -->
    <GalleryDialog
      :width="width"
      :galleryDialogVisible="galleryDialogVisible"
      v-on:disable-galleryDialog="
        updateDialogVisible({ type: 'gallery', isVisible: false })
      "
    />
  </div>
</template>
<script>
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import { scrollTo } from "vue-scrollto";

import Aside from "@/components/layouts/Aside";
import GalleryDialog from "@/components/condos/GalleryDialog";
import PhotoGrid from "@/components/condos/PhotoGrid";
import InfoEditor from "@/components/condos/InfoEditor";
import CondoInfo from "@/components/condos/CondoInfo";
import CondoProfile from "@/components/condos/CondoProfile";
import CondoReviews from "@/components/condos/CondoReviews";
import CondoVideos from "@/components/condos/CondoVideos";
import CondoNearby from "@/components/condos/CondoNearby";
import CondoLayouts from "@/components/condos/CondoLayouts";
import CondoBuildings from "@/components/condos/CondoBuildings";

export default {
  name: "Condo",
  components: {
    Aside,
    GalleryDialog,
    PhotoGrid,
    InfoEditor,
    CondoInfo,
    CondoProfile,
    CondoNearby,
    CondoReviews,
    CondoVideos,
    CondoLayouts,
    CondoBuildings,
  },
  data() {
    return {
      width: "md",
      condoId: this.$route.params.id,
      stationId: "",
      stationDistance: "",
      infoDialogVisible: false,
    };
  },
  beforeMount() {
    this.fetchCondo(this.condoId);
  },
  created() {
    // https://codepen.io/sethdavis512/pen/EvNKWw
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchLines();
    this.fetchStations();
  },
  watch: {
    $route() {
      this.condoId = this.$route.params.id;
      this.fetchCondo(this.condoId);
      scrollTo("#condoProfile", {
        container: "#condoProfile",
        easing: "ease-in",
        duration: 10,
        offset: 0,
      });
    },
    condo: function() {
      if (this.condo) {
        this.getNearestStation();
        document.title =
          (this.condo.info ? this.condo.info.name : this.condo.name) +
          " | CondoRail";
      } else {
        router.push({
          name: "Home",
        });
      }
    },
  },
  methods: {
    ...mapActions([
      "updateLineStation",
      "fetchLines",
      "fetchStation",
      "fetchStations",
      "fetchCondo",
      "fetchCondos",
      "updateScreenType",
      "updateScreenWidth",
      "updateDialogVisible",
    ]),
    handleResize() {
      // screenType
      var width = window.innerWidth;

      if (width < 768) {
        this.updateScreenType("xs");
      } else if (width < 992) {
        this.updateScreenType("sm");
      } else {
        this.updateScreenType("md");
      }
      // screenWidth
      if (width > 1200) {
        this.updateScreenWidth("45%");
      } else {
        this.updateScreenWidth(
          Math.min(String(160 - parseInt(width / 10)), 100) + "%"
        );
      }
    },
    handleInfoCommand(command) {
      if (command == "edit") {
        // this.$message("click on item " + command);
        this.infoDialogVisible = true;
      }
    },
    getMapURL: function() {
      let url = "https://www.google.com/maps/preview?q=";
      url += this.condo.name.replaceAll(" ", "+");
      return url;
    },
    getNearestStation() {
      const obj = this.condo.station_distances;
      this.stationId = Object.keys(obj).reduce((a, b) =>
        obj[a] < obj[b] ? a : b
      );
      if (this.activeStation != this.stationId) {
        this.updateLineStation({ lineId: "", stationId: this.stationId });
        this.fetchStation(this.stationId);
        this.fetchCondos(this.stationId);
      }
      this.stationDistance = parseInt(obj[this.stationId]);
    },
  },
  computed: {
    ...mapGetters([
      "gmapsKey",
      "condo",
      "station",
      "activeStation",
      "activeLine",
      "getLoginStatus",
      "screenWidth",
      "screenType",
      "galleryDialogVisible",
    ]),
    console: () => console,
  },
};
</script>
<style scoped lang="scss">
#condoProfile {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f7f7f7;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.el-col {
  border-radius: 4px;
}

.condo-info {
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.75rem;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-menu-item {
  font-size: 16px;
  i {
    font-size: 24px;
    padding-bottom: 1px;
    margin-right: 16px;
  }
}
</style>
