<template>
  <div>
    <div style="position:relative; padding-top:50%;">
      <iframe
        width="100%"
        height="100%"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
        :src="video.video_url"
        style="position:absolute; top:0; left:0; border-radius: 1rem;"
      />
    </div>
    <el-row class="pt-1 centered">
      <el-col :span="16">
        <UserProfile
          :userId="video.user_id || 'BWxOxFtHErbl0J0cXCmzl98zaXD3'"
        />
      </el-col>

      <el-col class="has-text-right is-size-6-7 ellipsis" :span="8">
        <el-dropdown
          v-if="getLoginStatus && getUser && video.user_id == getUser.user.uid"
          trigger="click"
          class="cursor-pointer"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            <i class="fa fa-ellipsis-h"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="is-size-7"
              :command="'delete'"
              icon="el-icon-delete"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <timeago v-else :datetime="video.datetime"></timeago>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { mapGetters } from "vuex";
import UserProfile from "@/components/users/UserProfile";
export default {
  name: "CondoVideoCard",
  props: ["video"],
  components: { UserProfile },
  methods: {
    handleCommand(command) {
      if (command == "delete") {
        let _this = this;

        this.$confirm(
          "You are about to delete this post. Are you sure?",
          "Confirm delete",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }
        )
          .then(() => {
            db.ref("condo_videos")
              .child(_this.video.condo_id + "/" + _this.video.video_id)
              .remove()
              .then(() => {
                _this.$message.success("Video deleted");
              })
              .catch(() => {
                _this.$message("An error occurred, please try again later.");
              });
          })
          .catch(() => {});
      }
    },
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus"]),
  },
};
</script>

<style>
iframe:hover {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  box-shadow: 5px 5px 15px rgb(126, 151, 173, 0.4);
}
</style>
