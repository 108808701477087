<template>
  <div class="pt-4">
    <br />
    <!-- <br /> -->
    <!-- <br /> -->
    <el-container class="mt-3" style="height: 96%; border: 1px solid #eee">
      <el-container>
        <Aside />
        <el-main class="px-0 pt-5 mt-2">
          <!-- Main Banner -->
          <div class="mx-5">
            <!-- Header -->
            <!-- Breadcrumb -->
            <div class="level is-mobile mb-0">
              <!-- Left -->
              <div class="level-left">
                <el-breadcrumb separator="/" class="mb-5">
                  <el-breadcrumb-item
                    class="is-size-6-7"
                    v-if="activeLine == '' || activeStation == ''"
                    :to="{ path: '/' }"
                  >
                    Home
                  </el-breadcrumb-item>
                  <el-breadcrumb-item
                    class="is-size-6-7"
                    v-else
                  >
                  <a   @click="$router.go(-1)">
                    <i class="fas fa-chevron-left mr-1"></i> Overview
                    </a>
                  </el-breadcrumb-item>
                  <el-breadcrumb-item class="is-size-6-7"
                    >Marketplace</el-breadcrumb-item
                  >
                </el-breadcrumb>
              </div>

              <!-- Right Options -->
              <div class="level-right pb-5" style=" vertical-align: text-top;">
                <!-- InfoEditor -->
                <el-dropdown
                  v-if="getLoginStatus"
                  @command="handleInfoCommand"
                  trigger="click"
                >
                  <span class="el-dropdown-link">
                    <i class="fa fa-ellipsis-h has-text-info"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit" icon="el-icon-edit">
                      Edit
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <InfoEditor
                  v-if="getLoginStatus"
                  :dialogVisible="infoDialogVisible"
                  v-on:disable-infoDialog="infoDialogVisible = false"
                />
              </div>
            </div>
            <!-- End Header -->
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Aside from "@/components/layouts/Aside";

export default {
  name: "Condo",
  components: {
    Aside,
  },
  data() {
    return {};
  },
  beforeCreate() {},
  beforeMount() {
    this.fetchCondo(this.condoId);
  },
  created() {},
  watch: {
    $route() {
      this.condoId = this.$route.params.id;
      this.fetchCondo(this.condoId);
    },
  },
  methods: {
    ...mapActions(["fetchCondo"]),
  },
  computed: {
    ...mapGetters(["condo", "getLoginStatus", "screenWidth", "screenType"]),
    console: () => console,
  },
};
</script>
<style scoped lang="scss"></style>
