var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"Edit Property Info","visible":_vm.show,"center":"","width":_vm.infoWidth},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.onClose}},[_c('el-form',{ref:"form",staticClass:"demo-ruleForm",attrs:{"label-width":"125px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Property name","prop":"name","rules":[{ required: true, message: 'Property name is required' }]}},[_c('el-input',{attrs:{"size":"medium","type":"text","maxlength":"40","show-word-limit":"","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"Developer","prop":"developer","rules":[]}},[_c('el-input',{attrs:{"size":"medium","type":"text","maxlength":"20","show-word-limit":"","autocomplete":"off"},model:{value:(_vm.form.developer),callback:function ($$v) {_vm.$set(_vm.form, "developer", $$v)},expression:"form.developer"}})],1),_c('el-form-item',{attrs:{"label":"Stories","prop":"stories","rules":[
          { type: 'number', message: 'stories must be a number' },
          {
            type: 'number',
            max: 100,
            message: 'stories must be lower than 100',
          } ]}},[_c('el-input',{attrs:{"size":"medium","autocomplete":"off"},model:{value:(_vm.form.stories),callback:function ($$v) {_vm.$set(_vm.form, "stories", _vm._n($$v))},expression:"form.stories"}})],1),_c('el-form-item',{attrs:{"label":"Units","prop":"units","rules":[
          { type: 'number', message: 'units must be a number' },
          {
            type: 'number',
            max: 10000,
            message: 'units must be lower than 10,000',
          } ]}},[_c('el-input',{attrs:{"size":"medium","autocomplete":"off"},model:{value:(_vm.form.units),callback:function ($$v) {_vm.$set(_vm.form, "units", _vm._n($$v))},expression:"form.units"}})],1),_c('el-form-item',{attrs:{"label":"Year Built","prop":"built","rules":[
          { type: 'number', message: 'Year must be a number' },
          {
            type: 'number',
            min: 1990,
            max: 2040,
            message: 'Invalid year range',
          } ]}},[_c('el-input',{attrs:{"size":"medium","autocomplete":"off"},model:{value:(_vm.form.built),callback:function ($$v) {_vm.$set(_vm.form, "built", _vm._n($$v))},expression:"form.built"}})],1),_c('el-form-item',{attrs:{"label":"฿Price/sq.m.","prop":"price","rules":[
          { type: 'number', message: 'price must be a number' },
          {
            type: 'number',
            min: 10000,
            max: 1000000,
            message: 'Price should be in range of ฿1,000 to ฿1,000,000',
          } ]}},[_c('ElCurrencyInput',{attrs:{"size":"medium"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", _vm._n($$v))},expression:"form.price"}})],1),_c('el-form-item',{attrs:{"label":"Website","prop":"website","rules":[{ type: 'url', message: 'Invalid URL' }]}},[_c('el-input',{attrs:{"size":"medium","type":"url","autocomplete":"off"},model:{value:(_vm.form.website),callback:function ($$v) {_vm.$set(_vm.form, "website", $$v)},expression:"form.website"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"button is-fullwidth is-size-6-7",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v(" Confirm ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }