<template>
  <el-aside class="hidden-xs-only  mr-3" width="230px">
    <el-menu
      default-active="1"
      :default-openeds="['1']"
      class="condo-aside"
      text-color="#3b353e"
    >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-s-home mr-2 pb-1"></i>Overview</template
        >
        <el-menu-item-group>
          <template slot="title">External Links</template>
          <el-menu-item index="1-2" @click="openGoogleMap()">
            <i class="fas fa-map-marker-alt mr-1 pb-1" />
            Google Map
            <i class="fa fa-external-link-alt ml-1 pb-1 is-size-7" />
          </el-menu-item>
          <el-menu-item index="1-3" @click="openYoutube()">
            <i class="fab fa-youtube mr-1 pb-1" />
            Search Youtube
            <i class="fa fa-external-link-alt ml-1 pb-1 is-size-7" />
          </el-menu-item>
          <el-menu-item index="1-4" @click="openGoogle()">
            <i class="fas fa-search mr-1 pb-1" />
            Search Google
            <i class="fa fa-external-link-alt ml-1 pb-1 is-size-7" />
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item
        index="2"
        @click="updateDialogVisible({ type: 'gallery', isVisible: true })"
      >
        <i class="el-icon-picture mr-2" />
        <span>Photos</span>
      </el-menu-item>

      <el-menu-item
        index="3"
        @click="updateDialogVisible({ type: 'review', isVisible: true })"
      >
        <i class="el-icon-link mr-2" /><span>Reviews</span>
      </el-menu-item>
      <el-menu-item
        index="4"
        @click="updateDialogVisible({ type: 'video', isVisible: true })"
      >
        <i class="el-icon-video-play mr-2" /><span>Videos</span>
      </el-menu-item>

      <!-- <el-menu-item index="5" disabled>
        <i class="el-icon-menu mr-2" /><span>Unit Plans</span>
      </el-menu-item> -->
      <!-- <el-menu-item index="6" disabled>
        <i class="el-icon-chat-line-square mr-2" /><span>Discussion</span>
      </el-menu-item> -->
      <el-menu-item index="7">
        <router-link v-if="condo != null" :to="{ name: 'Market' }">
          <i class="el-icon-s-shop mr-2" /><span>Marketplace</span>
        </router-link>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Aside",
  methods: {
    ...mapActions(["updateDialogVisible"]),
    openGoogleMap: function() {
      let url = "https://www.google.com/maps/preview?q=";
      url += this.condo.name.replaceAll(" ", "+");
      if (url) window.open(url, "_blank");
    },
    openYoutube: function() {
      let name = "";
      if (this.condo.info) {
        name = this.condo.info.name;
      } else {
        name = this.condo.name;
      }
      let url =
        "https://www.youtube.com/results?search_query=" +
        name.replaceAll(" ", "+");

      if (url) window.open(url, "_blank");
    },
    openGoogle: function() {
      let name = "";
      if (this.condo.info) {
        name = this.condo.info.name;
      } else {
        name = this.condo.name;
      }
      let url = "https://www.google.com/search?q=" + name.replaceAll(" ", "+");
      if (url) window.open(url, "_blank");
    },
  },
  computed: {
    ...mapGetters([
      "condo",
      "activeStation",
      "activeLine",
      "getLoginStatus",
      "screenWidth",
      "screenType",
    ]),
  },
};
</script>

<style lang="scss">
.condo-aside {
  font-weight: 500;
}

.el-aside {
  // background-color: #f7f7f7;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(127, 137, 168, 0.1);

  border-radius: none !important;
  padding-top: 60px;
  .el-menu {
    border-right: none;
    border-radius: 1rem !important;
    .el-menu-item a {
      display: block;
    }
  }
}
</style>
