<template>
  <div>
    <figure class="image is-3by2">
      <img
        class="cover cursor-pointer"
        :src="photo.src"
        @click="$emit('clickPhoto')"
      />
    </figure>

    <div class="top-right"></div>

    <el-row class="pt-1 centered">
      <el-col :span="16">
        <UserProfile v-if="photo.user_id" :userId="photo.user_id" />
        <UserProfile v-else userId="0" />
      </el-col>

      <el-col class="has-text-right is-size-6-7 ellipsis" :span="8">
        <el-dropdown
          v-if="getLoginStatus && getUser && photo.user_id == getUser.user.uid"
          trigger="click"
          class="cursor-pointer"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            <i class="fa fa-ellipsis-h"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="is-size-7"
              command="delete"
              icon="el-icon-delete"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <timeago v-else :datetime="photo.datetime"></timeago>
      </el-col>
    </el-row>

    <!-- Confirm Dialog -->
    <el-dialog
      title="Confirm delete"
      :visible.sync="deleteDialogVisible"
      :width="screenWidth"
      center
      append-to-body
    >
      <div class="has-text-centered pt-0 pb-4">
        You are about to delete this image. Are you sure?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="
            deleteDialogVisible = false;
            deletePhoto();
          "
        >
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { db, storage } from "@/firebase";
import { mapGetters } from "vuex";
import UserProfile from "@/components/users/UserProfile";
export default {
  name: "PhotoCard",
  props: ["photo"],
  components: { UserProfile },
  data() {
    return { deleteDialogVisible: false };
  },
  methods: {
    handleCommand(command) {
      if (command == "delete") {
        this.deleteDialogVisible = true;
      }
    },
    deletePhoto() {
      if (this.getLoginStatus) {
        let _this = this;
        let storage_child =
          "condos/" + _this.photo.condo_id + "/gallery/" + _this.photo.photo_id;
        db.ref("condo_photos")
          .child(_this.photo.condo_id + "/" + _this.photo.photo_id)
          .remove()
          .then(() => {
            _this.$message.success("Photo deleted");
            storage
              .ref()
              .child(storage_child + ".jpg")
              .delete()
              .then(() => {
                _this.$message("Storage deleted");
              });
            storage
              .ref()
              .child(storage_child + "_500x500.jpg")
              .delete()
              .then(() => {
                _this.$message("Tumb deleted");
              });
          })
          .catch(() => {
            _this.$message("An error occurred, please try again later.");
          });
      }
    },
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus", "screenWidth"]),
  },
};
</script>

<style></style>
