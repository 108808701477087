<template lang="">
  <div class="condo-info">
    <el-row :gutter="5">
      <el-col v-if="stories" :xs="10" :sm="10" :md="11">
        <CondoAttribute
          attribute="Stories"
          :value="stories"
          icon="fa fa-layer-group"
        />
      </el-col>
      <el-col v-if="units" :xs="13" :sm="13" :md="13">
        <CondoAttribute
          attribute="Units"
          :value="units"
          icon="fa fa-th-large"
        />
      </el-col>
      <!-- <el-col :span="8">
        <CondoAttribute attribute="Parking %" :value="parking" icon="fa fa-car" />
      </el-col> -->

      <el-col :xs="10" :sm="10" :md="11">
        <CondoAttribute
          attribute="Built"
          :value="built"
          icon="fa fa-calendar-alt"
        />
      </el-col>
      <el-col :xs="13" :sm="13" :md="13">
        <CondoAttribute
          attribute="฿Price/sq.m."
          :value="formatCurrency(price)"
          icon="fa fa-wallet"
        />
      </el-col>

      <el-col :xs="24" :sm="24" :md="24">
        <CondoAttribute
          attribute="Website"
          :value="website"
          icon="fa fa-link"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import CondoAttribute from "@/components/condos/CondoAttribute";

export default {
  name: "CondoInfo",
  props: ["info"],
  components: {
    CondoAttribute,
  },
  computed: {},
  data() {
    return this.getDefaultInfo();
  },
  watch: {
    info: function() {
      Object.assign(this.$data, this.getDefaultInfo());
    },
  },
  methods: {
    getDefaultInfo() {
      return {
        stories: this.info ? this.info.stories : null,
        units: this.info ? this.info.units : null,
        // parking: this.info ? this.info.parking : null,
        built: this.info ? this.info.built : null,
        price: this.info ? this.info.price : null,
        website: this.info ? this.info.website : null,
      };
    },
    formatCurrency(value) {
      if (value) {
        return new Intl.NumberFormat("en-US").format(value);
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.condo-info {
  background: #fff;
  padding: 0.75rem;
  border-radius: 0.75rem;
}
</style>
