<template>
  <el-dialog
    title="Posts & Reviews"
    :visible.sync="show"
    width="30%"
    @close="onClose"
    fullscreen
    center
  >
    <el-row class="review-dialog">
      <el-col
        :xs="24"
        :sm="6"
        :md="6"
        class="p-3"
        v-for="(review, index) in reviews"
        :key="index"
      >
        <CondoReviewCard :review="review" cardHeight="220px" />

        <el-row class="pt-1 centered">
          <el-col :span="16">
            <UserProfile :userId="review.user_id" />
          </el-col>

          <el-col class="has-text-right is-size-6-7 ellipsis" :span="8">
            <timeago :datetime="review.datetime"></timeago>
          </el-col>
        </el-row>
      </el-col>

      <!-- Help sharing -->
      <el-col :xs="24" :sm="6" :md="6" class="p-3" v-if="reviews.length > 0">
        <div
          class="card review-blank"
          :style="{
            width: '100%',
            height: '220px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              Help the community
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some blog posts.
            </p>
          </a>
        </div>
      </el-col>

      <!-- No posts yet -->
      <el-col :xs="24" :sm="6" :md="6" class="p-3" v-if="reviews.length == 0">
        <div
          class="card review-blank"
          :style="{
            width: '100%',
            height: '173px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              No Posts Yet
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some content.
            </p>
          </a>
        </div>
      </el-col>

      <br />
    </el-row>
  </el-dialog>
</template>

<script>
import { db } from "@/firebase";
import CondoReviewCard from "./CondoReviewCard";

import UserProfile from "@/components/users/UserProfile";

export default {
  name: "CondoReviewsDialog",
  props: ["reviewsDialogVisible"],
  components: {
    CondoReviewCard,
    UserProfile,
  },
  data() {
    return {
      author: {},
      show: false,
      reviews: [],
      foo: null,
    };
  },
  beforeMount: function() {
    this.paginateQueryReivews();
  },
  created() {},
  watch: {
    reviewsDialogVisible: function() {
      this.show = this.reviewsDialogVisible;
    },
    $route() {
      this.paginateQueryReivews();
    },
  },

  methods: {
    onClose() {
      this.$emit("disable-reviewDialog");
    },
    paginateQueryReivews() {
      db.ref("condo_reviews")
        .child(this.$route.params.id)
        .orderByChild("timestamp")
        .limitToLast(100)
        .on("value", (snapshot) => {
          this.reviews = [];
          var docs = snapshot.val();
          if (docs) {
            for (const value of Object.values(docs)) {
              this.reviews.push(value);
            }
            // (b,a) = descending order
            this.reviews = this.reviews.sort((b, a) => a.likes - b.likes);
          }
        });
      // https://firebase.google.com/docs/firestore/query-data/query-cursors
    },
  },
};
</script>

<style lang="scss">
.el-dialog.is-fullscreen {
  margin-top: 9vh !important;
  height: 91vh !important;
  // background-color: #1d1f20;
  background-color: #f7f7f7;
  border-radius: 0.7rem 0.7rem 0 0 !important;
  border-top: 1rem solid #f7f7f7;

  .el-dialog__header,
  .el-dialog__headerbtn {
    margin-top: -1rem;
    // border-radius: 0.7rem !important;
    // z-index: 3;
    // width: 100%;
    // position: fixed;
    // background-color: #1d1f20;
    // border-bottom: none !important;

    // padding-top: 0px;

    // border-bottom: 1px solid #353636;
    // .el-dialog__title {
    // color: rgb(240, 240, 240);
    // }
  }

  .el-dialog__body {
    border-radius: 0rem !important;
  }

  scrollbar-width: none;
}
</style>
