<template>
  <div
    v-if="building"
    class="card building-card"
    :style="{
      height: this.cardHeight,
      width: this.cardWidth,
    }"
  >
    <header class="card-header" style="  border-bottom: 1px solid #eee;">
      <p class="card-header-title is-size-6-7 has-text-weight-medium">
        <span style="text-transform: capitalize;">{{ building.name }}</span>
      </p>
      <div class="card-header-icon pl-1 py-0" aria-label="more options">
        <el-dropdown
          v-if="
            getLoginStatus && getUser && building.user_id == getUser.user.uid
          "
          trigger="click"
          class="p-2"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            <i class="fa fa-ellipsis-h"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="is-size-7"
              command="edit"
              icon="el-icon-edit"
            >
              Edit
            </el-dropdown-item>
            <el-dropdown-item
              class="is-size-7"
              command="delete"
              icon="el-icon-delete"
            >
              Delete
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>
    <div class="card-content p-3">
      <figure class="image is-4by3">
        <img
          v-lazy="building.photo_url"
          class="building-image"
          alt=""
          @click="$emit('clickPhotoIndex', index)"
          ref="condoImage"
        />
      </figure>
    </div>

    <!-- <footer class="card-footer">
      <span href="#" class="card-footer-item">Size {{ building.size }}</span>
      <span href="#" class="card-footer-item">Bed {{ building.beds }}</span>
      <span href="#" class="card-footer-item">Bath {{ building.baths }}</span>
    </footer> -->

    <!-- Edit Dialog -->
    <el-dialog
      v-if="getLoginStatus"
      title="Add Unit Layouts"
      :visible.sync="editDialogVisible"
      :width="screenWidth"
      top="15vh"
      center
    >
      <el-form
        :inline="true"
        :model="form"
        ref="building-form"
        label-width="110px"
        @submit.prevent.native="
          submitUpload();
          editDialogVisible = false;
        "
      >
        <el-form-item label="Building Name" prop="name">
          <el-input
            v-model="form.name"
            type="text"
            maxlength="12"
            show-word-limit
            autocomplete="off"
            placeholder="Building name (e.g., A, B, etc.)"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <button
          v-if="form.size != '' && form.beds != '' && form.baths != ''"
          class="button is-primary is-fullwidth"
          @click="
            submitUpload();
            editDialogVisible = false;
          "
        >
          <div class="is-size-6-7 has-text-weight-bold">Save</div>
        </button>
        <button v-else class="button is-primary is-fullwidth" disabled>
          <div class="is-size-6-7 has-text-weight-bold">Save</div>
        </button>
      </span>
    </el-dialog>

    <!-- Confirm Dialog -->
    <el-dialog
      title="Confirm delete"
      :visible.sync="deleteDialogVisible"
      :width="screenWidth"
      center
      append-to-body
    >
      <div class="has-text-centered pt-0 pb-4">
        You are about to delete this post. Are you sure?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="
            deleteDialogVisible = false;
            deletePost();
          "
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { db, storage } from "@/firebase";
import { mapGetters } from "vuex";
export default {
  name: "CondoReviewCard",
  props: ["building", "index", "cardHeight", "cardWidth"],
  data() {
    return {
      image: String,
      imageUrl: String,
      editDialogVisible: false,
      deleteDialogVisible: false,
      form: {
        name: this.building.name || "",
      },
    };
  },
  watch: {
    editDialogVisible: function() {
      this.form = {
        name: this.building.name || "",
      };
    },
    building: function() {
      this.setImage();
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "delete") {
        this.deleteDialogVisible = true;
      } else if (command == "edit") {
        this.editDialogVisible = true;
      }
    },
    submitUpload() {
      this.$refs["building-form"].validate((valid) => {
        if (valid) {
          let _this = this;
          db.ref("buildings")
            .child(_this.building.building_id)
            .update(_this.form, (error) => {
              if (error) {
                _this.$message("An error occurred, please try again");
              } else {
                _this.$message.success("Layout edited");
                _this.editDialogVisible = false;
                _this.$refs["building-form"].resetFields();
              }
            });
        } else {
          this.$message("Invalid input, please check once again");
          return false;
        }
      });
    },
    deletePost() {
      if (this.getLoginStatus) {
        let _this = this;
        let storage_child =
          "condos/" +
          _this.building.condo_id +
          "/buildings/" +
          _this.getUser.user.uid +
          "/";

        storage
          .ref()
          .child(storage_child + _this.building.filename_thumbnail)
          .delete()
          .then(() => {
            _this.$message(_this.building.name + " image deleted");
          })
          .catch(() => {
            _this.$message(
              "Storage error occurred, please try again later. #1"
            );
          });

        storage
          .ref()
          .child(storage_child + _this.building.filename)
          .delete()
          .then(() => {
            db.ref("buildings")
              .child(_this.building.building_id)
              .remove()
              .then(() => {
                _this.$message.success(_this.building.name + " data deleted");
              })
              .catch(() => {
                _this.$message("Data error occurred, please try again later.");
              });
          })
          .catch(() => {
            _this.$message(
              "Storage error occurred, please try again later. #2"
            );
          });
      }
    },

    setImage() {
      this.imageUrl = this.building.photo_url;
      var that;
      var highResImage = new Image();
      that = this;
      highResImage.onload = function() {
        that.image = that.imageUrl;
      };
      highResImage.src = this.imageUrl;
    },
  },
  mounted: function() {
    this.setImage();
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus", "fullscreen", "screenWidth"]),
    console: () => console,
  },
};
</script>

<style lang="scss">
.building-card {
  // background: #f3f3f5;
  border: 1px solid #eee !important;
}

.building-card:hover {
  box-shadow: 5px 5px 15px rgb(126, 151, 173, 0.2) !important;
}

.building-image {
  object-fit: cover;
  border-radius: 0.7rem;
}
</style>
