<template>
  <div class="media centered">
    <div class="media-left profile-loader-24 mr-3">
      <figure class="image is-24x24">
        <img v-if="picture" :src="picture" class="is-rounded" atl="" lazy />
      </figure>
    </div>
    <div class="media-content">
      <p class="title is-size-6-7">
        {{ name }}
      </p>
    </div>
  </div>
</template>
<script>
import { db } from "@/firebase";

export default {
  name: "UserProfile",
  props: ["userId"],
  data() {
    return {
      name: "",
      picture: "",
    };
  },
  watch: {
    userId: function() {
      if (this.userId == "0") {
        this.name = "Google Map";
        this.randomProfileImage();
      } else {
        this.getUser();
      }
    },
  },
  methods: {
    getUser() {
      db.ref("users_profile/" + this.userId).once("value", (snapshot) => {
        var user = snapshot.val();
        if (user != null) {
          this.name = user.username || user.name;
          if (this.name == "Condo Crew") {
            this.randomProfileImage();
          } else {
            this.picture = user.picture;
          }
        } else {
          this.getName();
          this.getPicture();
        }
      });
    },
    randomProfileImage() {
      let max = 13;
      let min = 1;
      var k = Math.floor(Math.random() * (max - min + 1) + min);
      this.picture = require("@/assets/avatars/p" + k + ".png");
    },
    getName() {
      db.ref("users/" + this.userId + "/name").once("value", (snapshot) => {
        this.name = snapshot.val();
      });
    },
    getPicture() {
      db.ref("users/" + this.userId + "/picture").once("value", (snapshot) => {
        this.picture = snapshot.val();
      });
    },
  },
  mounted: function() {
    if (this.userId == "0") {
      this.name = "Condo Crew";
      this.randomProfileImage();
    } else {
      this.getUser();
    }
  },
  computed: {
    console: () => console,
  },
};
</script>
<style scoped>
/* .img-frame {
  border: 3px solid rgb(34, 196, 136);
} */
</style>
