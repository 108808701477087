<template>
  <el-dialog
    title="Property Photos"
    :visible.sync="show"
    width="30%"
    @close="onClose"
    center
    fullscreen
  >
    <CoolLightBox
      :items="photos"
      :index="index"
      @close="index = null"
      :slideshow="false"
      slideshowColorBar="#fff"
    >
    </CoolLightBox>
    <el-row>
      <el-col
        :xs="24"
        :sm="6"
        :md="6"
        class="image-container p-3"
        v-for="(photo, i) in photos"
        :key="i"
      >
        <PhotoCard :photo="photo" v-on:clickPhoto="index = i" />
      </el-col>
      <br />
    </el-row>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import gmaps from "@/utils/gmaps";
import PhotoCard from "@/components/condos/PhotoCard";

export default {
  name: "GalleryDialog",
  props: ["galleryDialogVisible", "width"],
  components: {
    PhotoCard,
  },
  data() {
    return {
      // author: {},
      show: false,
      index: null,
      photos: [],
      loading: true,
      getPlacePhoto: gmaps.getPlacePhoto,
    };
  },
  beforeMount: function() {
    this.getPhotos();
  },
  created() {},
  watch: {
    galleryDialogVisible: function() {
      this.show = this.galleryDialogVisible;
      // console.log(this.show);
    },
    condoPhotos: function() {
      if (this.condoPhotos) {
        this.getPhotos();
      }
    },
  },

  methods: {
    onClose() {
      this.$emit("disable-galleryDialog");
    },
    getPhotos() {
      this.photos = [];

      this.N = this.condoPhotos.length;

      for (const [, obj] of Object.entries(this.condoPhotos)) {
        this.photos.push({
          src: obj.photo_url,
          photo_id: obj.photo_id,
          user_id: obj.user_id,
          condo_id: obj.condo_id,
          datetime: obj.datetime,
        });
        this.loading = false;
      }

      // if (this.N == 0) {
      //   this.loading = false;
      //   this.photos = [];
      //   this.photos.push({
      //     src: this.getPlacePhoto(
      //       this.gmapsKey,
      //       this.condo.photos[0].photo_reference,
      //       2000
      //     ),
      //   });
      //   // console.log(this.photos);
      // }
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getLoginStatus",
      "condo",
      "condoPhotos",
      "gmapsKey",
    ]),
    console: () => console,
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 1.25rem;
}

// .el-dialog.is-fullscreen {
//   margin-top: 9vh !important;
//   height: 91vh !important;
//   // background-color: #1d1f20;
//   background-color: #f7f7f7;
//   border-radius: 0.7rem 0.7rem 0 0 !important;
//   border-top: 1rem solid #f7f7f7;

//   .el-dialog__header,
//   .el-dialog__headerbtn {
//     margin-top: -1rem;
//     // border-radius: 0.7rem !important;
//     // z-index: 3;
//     // width: 100%;
//     // position: fixed;
//     // background-color: #1d1f20;
//     // border-bottom: none !important;

//     // padding-top: 0px;

//     // border-bottom: 1px solid #353636;
//     // .el-dialog__title {
//     // color: rgb(240, 240, 240);
//     // }
//   }

//   .el-dialog__body {
//     border-radius: 0rem !important;
//   }

//   scrollbar-width: none;
// }
</style>
