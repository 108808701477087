<template>
  <el-dialog
    v-if="!getLoginStatus"
    title="Log in or sign up"
    :visible.sync="show"
    :width="screenWidth"
    @close="onClose"
    center
  >
    <div class="title is-6">Welcome to CondoRail</div>
    <div class="subtitle is-6 pt-1" style="line-height: 1.5;">
      คอนโดโซเชียลมีเดีย และแผนที่คอนโดใกล้รถไฟฟ้า BTS, MRT, และ Airport Link
    </div>

    <span slot="footer" class="dialog-footer">
      <p class="has-text-left has-text-info is-size-7 pb-3">
        Sign in เพื่อเริ่มโพสและแชร์คอนเทนต์
      </p>
      <el-button class="button sign-in" @click="signUserInGoogle">
        <strong> <i class="fab fa-google mr-2"></i>Sign in with Google</strong>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginDialog",
  props: ["dialogVisible"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    dialogVisible: function() {
      this.show = this.dialogVisible;
    },
  },
  methods: {
    ...mapActions(["signUserInGoogle"]),
    onClose() {
      this.$emit("disable-loginDialog");
    },
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus", "screenWidth"]),
  },
};
</script>

<style></style>
