var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{},[_c('el-col',{attrs:{"lg":24}},[_c('nav',{staticClass:"level mx-2 mb-4 is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"title is-5"},[_vm._v("Videos")])]),_c('div',{staticClass:"level-right is-size-6-7"},[_c('a',{on:{"click":function($event){return _vm.updateDialogVisible({ type: 'video', isVisible: true })}}},[_vm._v("see more")])])]),_c('div',{staticClass:"scrolling-wrapper-video"},[_c('div',{staticClass:"video-button",on:{"click":function($event){return _vm.updateDialogVisible({ type: 'postVideo', isVisible: true })}}},[_c('i',{staticClass:"fab fa-youtube"}),_c('div',{staticClass:"button-text"},[_vm._v(" Youtube ")])]),_vm._l((_vm.videos),function(video,index){return _c('iframe',{key:index,staticClass:"youtube",attrs:{"width":"321","height":"173","allowfullscreen":"allowfullscreen","mozallowfullscreen":"mozallowfullscreen","msallowfullscreen":"msallowfullscreen","oallowfullscreen":"oallowfullscreen","webkitallowfullscreen":"webkitallowfullscreen","src":video.video_url}})}),(_vm.videos.length > 0)?_c('div',{staticClass:"card video-blank",style:({
          width: '250px',
          height: '173px',
        })},[_c('a',{staticClass:"card-header mt-5",staticStyle:{"display":"block"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"card-header-title is-size-6 py-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" Help sharing ")]),_c('p',{staticClass:"card-header-title is-size-6-7 has-text-weight-medium pt-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" We're looking for some videos. ")])])]):_vm._e(),(_vm.videos.length == 0)?_c('div',{staticClass:"card video-blank",style:({
          width: '250px',
          height: '173px',
        })},[_c('a',{staticClass:"card-header mt-5",staticStyle:{"display":"block"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"card-header-title is-size-6 py-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" No Videos Yet ")]),_c('p',{staticClass:"card-header-title is-size-6-7 has-text-weight-medium pt-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" We're looking for some content. ")])])]):_vm._e()],2),_c('CondoVideosDialog',{attrs:{"videosDialogVisible":_vm.videosDialogVisible},on:{"disable-videoDialog":function($event){return _vm.updateDialogVisible({ type: 'video', isVisible: false })}}}),(_vm.getLoginStatus)?_c('el-dialog',{attrs:{"title":"Share YouTube Link","visible":_vm.postVideoModal,"width":_vm.screenWidth,"top":"15vh","center":""},on:{"update:visible":function($event){_vm.postVideoModal=$event}}},[_c('el-form',{ref:"video-form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"url","rules":[
            { required: true, message: 'url is required' },
            { type: 'url', message: 'Invalid YouTube url' } ]}},[_c('el-input',{ref:"youtube-input",attrs:{"placeholder":"Youtube review link (https://www.youtube.com/watch)"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"fab fa-youtube"})])],2)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(
            _vm.form.url.includes('https://www.youtube.com/watch') ||
              _vm.form.url.includes('https://youtu.be/')
          )?_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){_vm.onSubmit();
            _vm.updateDialogVisible({ type: 'postVideo', isVisible: false });}}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])]):_c('button',{staticClass:"button is-primary is-fullwidth",attrs:{"disabled":""}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])])])],1):_vm._e(),_c('LoginDialog',{attrs:{"dialogVisible":_vm.postVideoDialogVisible},on:{"disable-loginDialog":function($event){return _vm.updateDialogVisible({ type: 'postVideo', isVisible: false })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }