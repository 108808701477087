<template>
  <el-row class="">
    <el-col :lg="24">
      <nav class="level mx-2 mb-4 is-mobile">
        <div class="level-left">
          <div class="title is-5">
            Locations
          </div>
        </div>
      </nav>

      <div class="card mx-1">
        <Map height="210px" />
      </div>
      <br />
      <div class="scrolling-wrapper-review pb-3">
        <CondoCardImage
          v-for="(nearbyCondo, condoId) in condos"
          :key="condoId"
          :nearbyCondo="nearbyCondo"
          cardHeight="173px"
          cardWidth="150px"
        />
      </div>

      <br />
    </el-col>
    <el-col :lg="24"> </el-col>
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CondoCardImage from "./CondoCardImage";
import Map from "@/components/maps/Map";

export default {
  name: "CondoNearby",
  components: {
    CondoCardImage,
    Map,
  },
  data() {
    return this.init();
  },
  beforeMount: function() {
    Object.assign(this.$data, this.init());
  },

  watch: {
    condo: function() {
      // console.log(this.condo.name);
    },
    condos: function() {
      // console.log(this.condos);
    },
  },
  methods: {
    init() {
      return {};
    },
  },
  computed: {
    ...mapActions([]),
    ...mapGetters(["condo", "condos"]),
    console: () => console,
  },
};
</script>
