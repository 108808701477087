<template>
  <el-row class="">
    <el-col :lg="24">
      <nav class="level mx-2 mb-4 is-mobile">
        <div class="level-left">
          <div class="title is-5">
            Layouts
            <el-button
              class="ml-1 px-2 py-1"
              type="primary"
              size="mini"
              @click="postLayoutDialogVisible = true"
              icon="el-icon-plus"
              round
              >Add</el-button
            >
          </div>
        </div>
      </nav>

      <div class="scrolling-wrapper-review">
        <!-- LayoutCard -->
        <CondoLayoutCard
          v-for="(layout, index) in layouts"
          :key="index"
          :index="index"
          :layout="layout"
          cardWidth="300px"
          cardHeight="100%"
          @clickPhotoIndex="clickPhotoIndex"
        />
      </div>

      <!-- LayoutDialog -->
      <el-dialog
        v-if="getLoginStatus"
        title="Add Unit Layouts"
        :visible.sync="postLayoutDialogVisible"
        :width="screenWidth"
        top="15vh"
        center
      >
        <el-form
          :inline="true"
          :model="form"
          ref="layout-form"
          label-width="100px"
          @submit.prevent.native="
            submitUpload();
            editDialogVisible = false;
          "
        >
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="form.name"
              type="text"
              maxlength="12"
              show-word-limit
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="Size (sq.m.)" prop="size">
            <el-input-number
              style="width: 100%;"
              v-model="form.size"
              :precision="2"
              :max="100"
              :controls="false"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="Beds" prop="beds">
            <el-input-number
              v-model="form.beds"
              :min="1"
              :max="5"
              :controls="false"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="Baths" prop="baths">
            <el-input-number
              v-model="form.baths"
              :min="1"
              :max="5"
              :controls="false"
            ></el-input-number>
          </el-form-item>
        </el-form>

        <el-upload
          ref="layout-upload"
          class="layout-upload"
          action
          drag
          list-type="picture-card"
          :file-list="fileList"
          :http-request="firebaseUpload"
          :auto-upload="false"
          :on-change="onChange"
        >
          <i class="el-icon-upload is-size-3 has-text-info my-2"></i>
          <div class="el-upload__text">
            Drop an image here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png file with a size less than 3Mb
          </div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
          <button
            v-if="
              fileList.length == 1 &&
                form.size != '' &&
                form.beds != '' &&
                form.baths != ''
            "
            class="button is-primary is-fullwidth"
            @click="
              submitUpload();
              postLayoutDialogVisible = false;
            "
          >
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
          <button v-else class="button is-primary is-fullwidth" disabled>
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
        </span>
      </el-dialog>
      <!-- End LayoutDialod -->

      <!-- LoginDialog -->
      <LoginDialog
        :dialogVisible="postLayoutDialogVisible"
        v-on:disable-loginDialog="postLayoutDialogVisible = false"
      />
      <!-- End LoginDialog -->

      <CoolLightBox
        :items="photos"
        :index="photoIndex"
        @close="photoIndex = null"
        :slideshow="false"
        slideshowColorBar="#fff"
      >
      </CoolLightBox>
    </el-col>
  </el-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { db, storage } from "@/firebase";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import CondoLayoutCard from "./CondoLayoutCard";
import LoginDialog from "@/components/users/LoginDialog";

export default {
  name: "CondoLayouts",
  components: {
    CondoLayoutCard,
    LoginDialog,
  },
  data() {
    return this.init();
  },
  beforeMount: function() {
    this.queryLayouts();
  },

  watch: {
    $route() {
      this.queryLayouts();
    },
    postLayoutDialogVisible: function() {
      this.fileList = [];
    },
  },
  methods: {
    ...mapActions([]),
    init() {
      return {
        width: String,
        layouts: [],
        photos: [],
        photoIndex: null,
        fileList: [],
        postLayoutDialogVisible: false,
        form: {
          name: "",
          size: "",
          beds: "",
          baths: "",
        },
      };
    },
    queryLayouts() {
      db.ref("layouts")
        .orderByChild("condo_id")
        .equalTo(this.$route.params.id)
        .on("value", (snapshot) => {
          this.layouts = [];
          this.photos = [];

          var docs = snapshot.val();
          if (docs) {
            for (const value of Object.values(docs)) {
              this.layouts.push(value);
            }
            // (b,a) = descending order
            this.layouts = this.layouts.sort((a, b) => a.size - b.size);

            var k = 1;
            var N = this.layouts.length;
            for (const obj of Object.values(this.layouts)) {
              this.photos.push({
                title: k++ + "/" + N,
                description:
                  obj.name +
                  ", " +
                  String(obj.size) +
                  " sqm., " +
                  String(obj.beds) +
                  " bed, " +
                  String(obj.baths) +
                  " bath" +
                  "</br></br><i class='has-size-7 th'>*ใช้เพื่อประกอบการให้ข้อมูลเพียงเท่านั้น</i>",
                src: obj.photo_url_orginal,
              });
            }
          }
        });
    },
    clickPhotoIndex(index) {
      this.photoIndex = index;
      // console.log(index);
    },
    submitUpload() {
      this.$refs["layout-form"].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.$refs["layout-upload"].submit();
        } else {
          this.$message("Invalid input, please check once again");
          return false;
        }
      });
    },
    firebaseUpload(payload) {
      if (this.getLoginStatus) {
        // console.log(payload);
        const ID = uuidv4();
        var original_url = "";
        let filename = ID + ".jpg";
        let filename_thumbnail = ID + "_500x500.jpg";
        let storage_child =
          "condos/" +
          this.condo.place_id +
          "/layouts/" +
          this.getUser.user.uid +
          "/";

        let _this = this;
        storage
          .ref()
          .child(storage_child + filename)
          .put(payload.file)
          .then(function(snapshot) {
            _this.$message.success("Image uploaded");

            snapshot.ref.getDownloadURL().then(function(url) {
              original_url = url;
              var data = {
                layout_id: ID,
                user_id: _this.getUser.user.uid,
                condo_id: _this.condo.place_id,
                datetime: new Date(),
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                filename: filename,
                filename_thumbnail: filename_thumbnail,
                photo_url: original_url,
                photo_url_orginal: original_url,
                name: _this.form.name.toUpperCase(),
                size: _this.form.size,
                beds: _this.form.beds,
                baths: _this.form.baths,
              };

              db.ref("layouts")
                .child(ID)
                .update(data, (error) => {
                  if (error) {
                    _this.$message("An error occurred, please try again");
                  } else {
                    _this.$message.success("Posted successfully");
                    _this.$refs["layout-form"].resetFields();
                  }
                });
              // end db.ref
            });
            // end snapshot.ref

            setTimeout(() => {
              storage
                .ref()
                .child(storage_child + filename_thumbnail)
                .getDownloadURL()
                .then(function(url) {
                  db.ref("layouts")
                    .child(ID)
                    .update({
                      photo_url: url,
                    })
                    .then(() => {
                      _this.$message.success("Thumbnail created");
                    });
                })
                .catch(() => {
                  _this.$message(
                    "Thumbnail error occurred, please try again later."
                  );
                });
              // end storage.ref
            }, 3000);
            // end timeout
          })
          .catch(() => {
            this.$message("Unable to create post");
          });
      }
    },
    onChange(file, fileList) {
      const fileType = file.raw.type;
      const isFileType = fileType === "image/jpeg" || fileType === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isFileType) {
        this.$message("Image must be JPG/PNG format");
      }
      if (!isLt2M) {
        this.$message("Please upload image size less than 3Mb");
      }

      if (isFileType && isLt2M) {
        this.fileList = [file];
      } else {
        fileList.pop();
      }
      return isFileType && isLt2M;
    },
  },
  computed: {
    ...mapGetters(["getUser", "condo", "getLoginStatus", "screenWidth"]),
    console: () => console,
  },
};
</script>

<style lang="scss">
.layout-blank {
  background-image: url("~@/assets/review_blank.png");
}

.layout-blank:hover {
  box-shadow: 3px 3px 20px rgb(126, 151, 173, 0.3) !important;
}

// .layout-button {
// height: 100px !important;
// padding-top: 65px;
// .button-text {
// padding-top: 20px ;
// }
// }

.layout-upload {
  .el-dialog__body {
    padding: 0px !important;
  }
  .el-upload--picture-card {
    width: 100%;
    line-height: 1.7;
    height: 95px;
    .el-upload-dragger {
      width: 100%;
      line-height: 0;
      height: 95px;
    }
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 100%;
    height: 100%;
    padding: 5% 10% 3% 10%;
    background: #eee;
  }
  .el-upload-list__item.is-ready {
    margin: 3px;
  }
  .el-upload-list__item-thumbnail {
    object-fit: cover;
  }
}
</style>
