<template>
  <el-dialog
    title="Videos"
    :visible.sync="show"
    width="30%"
    @close="onClose"
    fullscreen
    center
  >
    <el-row class="video-dialog">
      <el-col
        :xs="24"
        :sm="6"
        :md="6"
        class="px-2 py-2"
        v-for="(video, index) in videos"
        :key="index"
      >
        <CondoVideoCard :video="video" />
      </el-col>

      <!-- Help sharing -->
      <el-col :xs="24" :sm="6" :md="6" class="p-3" v-if="videos.length > 0">
        <div style="position:relative;padding-top:50%;">
          <div
            class="card video-blank"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
          >
            <a class="card-header mt-5" style="display: block;" target="_blank">
              <p
                class="card-header-title is-size-6 py-0"
                style="color: #566e82; display: block; word-break: break-word;"
              >
                Help sharing
              </p>
              <p
                class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
                style="color: #566e82; display: block; word-break: break-word;"
              >
                We're looking for some content.
              </p>
            </a>
          </div>
        </div>
      </el-col>

      <!-- No videos yet -->
      <el-col :xs="24" :sm="6" :md="6" class="p-3" v-if="videos.length == 0">
        <div style="position:relative;padding-top:50%;">
          <div
            class="card video-blank"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
          >
            <a class="card-header mt-5" style="display: block;" target="_blank">
              <p
                class="card-header-title is-size-6 py-0"
                style="color: #566e82; display: block; word-break: break-word;"
              >
                No Videos Yet
              </p>
              <p
                class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
                style="color: #566e82; display: block; word-break: break-word;"
              >
                We're looking for some content.
              </p>
            </a>
          </div>
        </div>
      </el-col>

      <br />
    </el-row>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase";
import CondoVideoCard from "@/components/condos/CondoVideoCard";

export default {
  name: "CondoVideosDialog",
  props: ["videosDialogVisible"],
  components: { CondoVideoCard },
  data() {
    return {
      author: {},
      show: false,
      videos: [],
      foo: null,
    };
  },
  beforeMount: function() {
    this.paginateQueryVideos();
  },
  created() {},
  watch: {
    videosDialogVisible: function() {
      this.show = this.videosDialogVisible;
    },
    $route() {
      this.paginateQueryVideos();
    },
  },

  methods: {
    onClose() {
      this.$emit("disable-videoDialog");
    },
    paginateQueryVideos() {
      db.ref("condo_videos")
        .child(this.$route.params.id)
        .orderByChild("likes")
        .limitToLast(100)
        .on("value", (snapshot) => {
          this.videos = [];
          var docs = snapshot.val();
          if (docs) {
            for (const value of Object.values(docs)) {
              this.videos.push(value);
            }
            // (b,a) = descending order
            this.videos = this.videos.sort((b, a) => a.likes - b.likes);
          }
        });
      // https://firebase.google.com/docs/firestore/query-data/query-cursors
    },
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus"]),
  },
};
</script>
