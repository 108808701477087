<template>
  <el-row class="">
    <el-col :lg="24">
      <nav class="level mx-2 mb-4 is-mobile">
        <div class="level-left">
          <div class="title is-5">Videos</div>
        </div>
        <div class="level-right is-size-6-7">
          <!-- <a :href="getYoutube()" target="_blank">
            <i class="fab fa-youtube is-size-6 has-text-danger"></i>
          </a> -->
          <a @click="updateDialogVisible({ type: 'video', isVisible: true })"
            >see more</a
          >
        </div>
      </nav>

      <div class="scrolling-wrapper-video">
        <div
          class="video-button"
          @click="updateDialogVisible({ type: 'postVideo', isVisible: true })"
        >
          <i class="fab fa-youtube"></i>
          <div class="button-text">
            Youtube
          </div>
        </div>

        <iframe
          v-for="(video, index) in videos"
          :key="index"
          class="youtube"
          width="321"
          height="173"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          :src="video.video_url"
        />

        <div
          v-if="videos.length > 0"
          class="card video-blank"
          :style="{
            width: '250px',
            height: '173px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              Help sharing
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some videos.
            </p>
          </a>
        </div>

        <div
          v-if="videos.length == 0"
          class="card video-blank"
          :style="{
            width: '250px',
            height: '173px',
          }"
        >
          <a class="card-header mt-5" style="display: block;" target="_blank">
            <p
              class="card-header-title is-size-6 py-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              No Videos Yet
            </p>
            <p
              class="card-header-title is-size-6-7 has-text-weight-medium pt-0"
              style="color: #566e82; display: block; word-break: break-word;"
            >
              We're looking for some content.
            </p>
          </a>
        </div>
      </div>

      <CondoVideosDialog
        :videosDialogVisible="videosDialogVisible"
        v-on:disable-videoDialog="
          updateDialogVisible({ type: 'video', isVisible: false })
        "
      />

      <!-- PostDialog -->
      <el-dialog
        v-if="getLoginStatus"
        title="Share YouTube Link"
        :visible.sync="postVideoModal"
        :width="screenWidth"
        top="15vh"
        center
      >
        <el-form :model="form" ref="video-form">
          <el-form-item
            prop="url"
            :rules="[
              { required: true, message: 'url is required' },
              { type: 'url', message: 'Invalid YouTube url' },
            ]"
          >
            <el-input
              ref="youtube-input"
              placeholder="Youtube review link (https://www.youtube.com/watch)"
              v-model="form.url"
            >
              <template slot="prepend"><i class="fab fa-youtube"></i></template>
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <button
            v-if="
              form.url.includes('https://www.youtube.com/watch') ||
                form.url.includes('https://youtu.be/')
            "
            class="button is-primary is-fullwidth"
            @click="
              onSubmit();
              updateDialogVisible({ type: 'postVideo', isVisible: false });
            "
          >
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
          <button v-else class="button is-primary is-fullwidth" disabled>
            <div class="is-size-6-7 has-text-weight-bold">Post</div>
          </button>
        </span>
      </el-dialog>
      <!-- End PostDialod -->

      <!-- LoginDialog -->
      <LoginDialog
        :dialogVisible="postVideoDialogVisible"
        v-on:disable-loginDialog="
          updateDialogVisible({ type: 'postVideo', isVisible: false })
        "
      />
      <!-- End LoginDialog -->
    </el-col>
  </el-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/firebase";
import firebase from "firebase/app";
import CondoVideosDialog from "./CondoVideosDialog";
import LoginDialog from "@/components/users/LoginDialog";

export default {
  name: "CondoVideos",
  components: {
    CondoVideosDialog,
    LoginDialog,
  },
  data() {
    return this.init();
  },
  beforeMount: function() {
    this.$rtdbBind(
      "videos",
      db.ref("condo_videos").child(this.$route.params.id)
    );
  },
  watch: {
    $route() {
      this.$rtdbBind(
        "videos",
        db.ref("condo_videos").child(this.$route.params.id)
      );
    },
  },
  methods: {
    ...mapActions(["updateDialogVisible"]),
    init() {
      return {
        videos: [],
        form: {
          url: "",
        },
      };
    },
    getYoutube: function() {
      let name = "";
      if (this.condo.info) {
        name = this.condo.info.name;
      } else {
        name = this.condo.name;
      }
      return (
        "https://www.youtube.com/results?search_query=" +
        name.replaceAll(" ", "+")
      );
    },
    onSubmit() {
      this.addVideoURL(this.form.url);
      this.$refs["youtube-input"].clear();
    },
    addVideoURL(url) {
      const video_id = this.youtube_parser(url);
      if (!video_id) {
        this.$message("Invalid URL, please try again");
        return null;
      }
      const video_url = "https://youtube.com/embed/" + video_id;
      var data = {
        user_id: this.getUser.user.uid,
        condo_id: this.condo.place_id,
        video_id: video_id,
        video_url: video_url,
        datetime: new Date(),
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        likes: 0,
      };
      db.ref("condo_videos")
        .child(this.condo.place_id + "/" + video_id)
        .update(data, (error) => {
          if (error) {
            this.$message("Please try again");
          } else {
            this.$message.success("Video posted, thanks for sharing");
          }
        });
    },
    youtube_parser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
  computed: {
    ...mapGetters([
      "condo",
      "getUser",
      "screenWidth",
      "getLoginStatus",
      "videosDialogVisible",
      "postVideoDialogVisible",
    ]),
    postVideoModal: {
      // https://forum.vuejs.org/t/elementui-dialog-modal-from-vuex-store/27976/2
      get() {
        return this.postVideoDialogVisible;
      },
      set(value) {
        this.updateDialogVisible({ type: "postVideo", isVisible: value });
      },
    },
    console: () => console,
  },
};
</script>
<style lang="scss">
.scrolling-wrapper-video {
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .card {
    // background-image: url("/youtube-bg.jpg");
    // background-color: #34495c;
    // background-size: 1000px 250px;
    cursor: pointer;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-right: 0.5rem;
    box-shadow: none;
    width: 160px;
    height: 220px;

    transition: all 600ms ease;
    position: relative;
    white-space: initial;
  }
  .card:hover {
    box-shadow: 5px 5px 15px rgb(126, 151, 173, 0.2);
  }
  .video-button {
    cursor: pointer;
    float: left;
    margin-right: 0.8rem;
    box-shadow: none;
    width: 95px;
    height: 173px;
    background: #f3f3f5;
    border-radius: 0.7rem;
    font-size: 0.8rem;

    padding-top: 65px;
    text-align: center;
    font-weight: 500;
    color: #777;
    transition: box-shadow 300ms ease-in-out;

    .button-text {
      padding-top: 55px;
    }
  }

  .video-button:hover {
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 5px 5px 15px rgb(126, 151, 173, 0.2);
  }

  // .youtube:first-child {
  //   // margin-left: 1rem;
  // }
  // .youtube:last-child {
  //   // margin-right: 0.5rem;
  // }
  .youtube {
    border-radius: 0.7rem;
    margin-right: 0.5rem;
  }
}

.video-blank {
  background-image: url("~@/assets/video_blank.png");
  cursor: pointer;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin-right: 0.5rem;
  box-shadow: none;
  width: 160px;
  height: 220px;

  transition: all 600ms ease;
  position: relative;
  white-space: initial;
}

.video-blank:hover {
  box-shadow: 3px 3px 20px rgb(126, 151, 173, 0.3) !important;
}

.youtube-button {
  background-color: transparent;
  border-color: #666;
}
</style>
