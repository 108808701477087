var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{},[_c('el-col',{attrs:{"lg":24}},[_c('nav',{staticClass:"level mx-2 mb-4 is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"title is-5"},[_vm._v(" Layouts "),_c('el-button',{staticClass:"ml-1 px-2 py-1",attrs:{"type":"primary","size":"mini","icon":"el-icon-plus","round":""},on:{"click":function($event){_vm.postLayoutDialogVisible = true}}},[_vm._v("Add")])],1)])]),_c('div',{staticClass:"scrolling-wrapper-review"},_vm._l((_vm.layouts),function(layout,index){return _c('CondoLayoutCard',{key:index,attrs:{"index":index,"layout":layout,"cardWidth":"300px","cardHeight":"100%"},on:{"clickPhotoIndex":_vm.clickPhotoIndex}})}),1),(_vm.getLoginStatus)?_c('el-dialog',{attrs:{"title":"Add Unit Layouts","visible":_vm.postLayoutDialogVisible,"width":_vm.screenWidth,"top":"15vh","center":""},on:{"update:visible":function($event){_vm.postLayoutDialogVisible=$event}}},[_c('el-form',{ref:"layout-form",attrs:{"inline":true,"model":_vm.form,"label-width":"100px"},nativeOn:{"submit":function($event){$event.preventDefault();_vm.submitUpload();
          _vm.editDialogVisible = false;}}},[_c('el-form-item',{attrs:{"label":"Name","prop":"name"}},[_c('el-input',{attrs:{"type":"text","maxlength":"12","show-word-limit":"","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"Size (sq.m.)","prop":"size"}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"precision":2,"max":100,"controls":false},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}})],1),_c('el-form-item',{attrs:{"label":"Beds","prop":"beds"}},[_c('el-input-number',{attrs:{"min":1,"max":5,"controls":false},model:{value:(_vm.form.beds),callback:function ($$v) {_vm.$set(_vm.form, "beds", $$v)},expression:"form.beds"}})],1),_c('el-form-item',{attrs:{"label":"Baths","prop":"baths"}},[_c('el-input-number',{attrs:{"min":1,"max":5,"controls":false},model:{value:(_vm.form.baths),callback:function ($$v) {_vm.$set(_vm.form, "baths", $$v)},expression:"form.baths"}})],1)],1),_c('el-upload',{ref:"layout-upload",staticClass:"layout-upload",attrs:{"action":"","drag":"","list-type":"picture-card","file-list":_vm.fileList,"http-request":_vm.firebaseUpload,"auto-upload":false,"on-change":_vm.onChange}},[_c('i',{staticClass:"el-icon-upload is-size-3 has-text-info my-2"}),_c('div',{staticClass:"el-upload__text"},[_vm._v(" Drop an image here or "),_c('em',[_vm._v("click to upload")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" jpg/png file with a size less than 3Mb ")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(
            _vm.fileList.length == 1 &&
              _vm.form.size != '' &&
              _vm.form.beds != '' &&
              _vm.form.baths != ''
          )?_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){_vm.submitUpload();
            _vm.postLayoutDialogVisible = false;}}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])]):_c('button',{staticClass:"button is-primary is-fullwidth",attrs:{"disabled":""}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])])])],1):_vm._e(),_c('LoginDialog',{attrs:{"dialogVisible":_vm.postLayoutDialogVisible},on:{"disable-loginDialog":function($event){_vm.postLayoutDialogVisible = false}}}),_c('CoolLightBox',{attrs:{"items":_vm.photos,"index":_vm.photoIndex,"slideshow":false,"slideshowColorBar":"#fff"},on:{"close":function($event){_vm.photoIndex = null}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }