var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.building)?_c('div',{staticClass:"card building-card",style:({
    height: this.cardHeight,
    width: this.cardWidth,
  })},[_c('header',{staticClass:"card-header",staticStyle:{"border-bottom":"1px solid #eee"}},[_c('p',{staticClass:"card-header-title is-size-6-7 has-text-weight-medium"},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.building.name))])]),_c('div',{staticClass:"card-header-icon pl-1 py-0",attrs:{"aria-label":"more options"}},[(
          _vm.getLoginStatus && _vm.getUser && _vm.building.user_id == _vm.getUser.user.uid
        )?_c('el-dropdown',{staticClass:"p-2",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"fa fa-ellipsis-h"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticClass:"is-size-7",attrs:{"command":"edit","icon":"el-icon-edit"}},[_vm._v(" Edit ")]),_c('el-dropdown-item',{staticClass:"is-size-7",attrs:{"command":"delete","icon":"el-icon-delete"}},[_vm._v(" Delete ")])],1)],1):_vm._e()],1)]),_c('div',{staticClass:"card-content p-3"},[_c('figure',{staticClass:"image is-4by3"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.building.photo_url),expression:"building.photo_url"}],ref:"condoImage",staticClass:"building-image",attrs:{"alt":""},on:{"click":function($event){return _vm.$emit('clickPhotoIndex', _vm.index)}}})])]),(_vm.getLoginStatus)?_c('el-dialog',{attrs:{"title":"Add Unit Layouts","visible":_vm.editDialogVisible,"width":_vm.screenWidth,"top":"15vh","center":""},on:{"update:visible":function($event){_vm.editDialogVisible=$event}}},[_c('el-form',{ref:"building-form",attrs:{"inline":true,"model":_vm.form,"label-width":"110px"},nativeOn:{"submit":function($event){$event.preventDefault();_vm.submitUpload();
        _vm.editDialogVisible = false;}}},[_c('el-form-item',{attrs:{"label":"Building Name","prop":"name"}},[_c('el-input',{attrs:{"type":"text","maxlength":"12","show-word-limit":"","autocomplete":"off","placeholder":"Building name (e.g., A, B, etc.)"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.form.size != '' && _vm.form.beds != '' && _vm.form.baths != '')?_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){_vm.submitUpload();
          _vm.editDialogVisible = false;}}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Save")])]):_c('button',{staticClass:"button is-primary is-fullwidth",attrs:{"disabled":""}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Save")])])])],1):_vm._e(),_c('el-dialog',{attrs:{"title":"Confirm delete","visible":_vm.deleteDialogVisible,"width":_vm.screenWidth,"center":"","append-to-body":""},on:{"update:visible":function($event){_vm.deleteDialogVisible=$event}}},[_c('div',{staticClass:"has-text-centered pt-0 pb-4"},[_vm._v(" You are about to delete this post. Are you sure? ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.deleteDialogVisible = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.deleteDialogVisible = false;
          _vm.deletePost();}}},[_vm._v("Confirm")])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }