<template>
  <div>
    <div v-if="!loading">
      <CoolLightBox
        :items="photos"
        :index="index"
        @close="index = null"
        :slideshow="false"
        slideshowColorBar="#fff"
      >
      </CoolLightBox>

      <div class="images-wrapper">
        <el-row
          v-if="this.photos.length >= 5 && this.width === 'md'"
          :gutter="6"
        >
          <el-col :span="12">
            <div v-if="N > 0">
              <figure class="image is-4by3">
                <img
                  class="cover"
                  :src="photos[0].src"
                  @click="index = 0"
                  style="border-radius: 0.7rem 0 0 0.7rem;"
                />
              </figure>
            </div>
            <div v-else>
              <figure class="image is-4by3">
                <img
                  class="condo-image curve-edge"
                  src="/blank.jpg"
                  style="border-radius: 0.7rem 0 0 0.7rem;"
                />
              </figure>
            </div>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="6" class="mb-1">
              <el-col :span="12">
                <div v-if="N > 1">
                  <figure class="image is-4by3">
                    <img
                      class="cover"
                      :src="photos[1].src"
                      @click="index = 1"
                      style="border-radius: 0;"
                    />
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-4by3">
                    <img
                      class="condo-image curve-edge"
                      src="/blank.jpg"
                      style="border-radius: 0;"
                    />
                  </figure>
                </div>
              </el-col>

              <el-col :span="12">
                <div v-if="N > 2">
                  <figure class="image is-4by3">
                    <img
                      class="cover"
                      :src="photos[2].src"
                      @click="index = 2"
                      style="border-radius: 0 0.7rem 0 0;"
                    />
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-4by3">
                    <img
                      class="condo-image curve-edge"
                      src="/blank.jpg"
                      style="border-radius: 0 0.7rem 0 0;"
                    />
                  </figure>
                </div>
              </el-col> </el-row
            ><el-row :gutter="6">
              <el-col :span="12">
                <div v-if="N > 3">
                  <figure class="image is-4by3">
                    <img
                      class="cover"
                      :src="photos[3].src"
                      @click="index = 3"
                      style="border-radius: 0;"
                    />
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-4by3">
                    <img
                      class="condo-image curve-edge"
                      src="/blank.jpg"
                      style="border-radius: 0;"
                    />
                  </figure>
                </div>
              </el-col>

              <el-col :span="12">
                <div v-if="N > 4">
                  <figure class="image is-4by3">
                    <img
                      class="cover"
                      :src="photos[4].src"
                      @click="index = 4"
                      style="border-radius: 0 0 0.7rem;"
                    />
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-4by3">
                    <img
                      class="condo-image curve-edge"
                      src="/blank.jpg"
                      style="border-radius: 0 0 0.7rem;"
                    />
                  </figure>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row v-else-if="this.photos.length > 0">
          <el-col class="" :span="24">
            <figure :class="figure">
              <img
                :src="photos[0].src"
                @click="index = 0"
                class="cover"
                alt="condo-banner"
                style="background-image: url('/blank.jpg');"
              />
            </figure>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else>
      <figure :class="figure">
        <img class="condo-image curve-edge" src="/loading.gif" />
      </figure>
    </div>
  </div>
</template>
<script>
// import { db } from "@/firebase";
import { mapActions, mapGetters } from "vuex";
import gmaps from "@/utils/gmaps";
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "PhotoGrid",
  props: ["condoId"],
  components: {
    // PulseLoader,
  },
  data() {
    return {
      figure: "",
      width: "md",
      index: null,
      N: -1,
      photos: [],
      loading: true,
      getPlacePhoto: gmaps.getPlacePhoto,
    };
  },
  created() {
    this.fetchCondoPhotos(this.condoId);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    condoId: function() {
      this.fetchCondoPhotos(this.condoId);
    },
    condoPhotos: function() {
      if (this.condoPhotos) {
        this.loading = true;
        this.photos = [];

        var k = 1;
        this.N = this.condoPhotos.length;

        for (const [, obj] of Object.entries(this.condoPhotos)) {
          this.photos.push({
            title: k++ + "/" + this.N,
            description:
              "Shared by CondoRail" +
              "</br></br><i class='has-size-7 th'>*ใช้เพื่อประกอบการให้ข้อมูลเพียงเท่านั้น</i>",
            src: obj.photo_url,
          });
          this.loading = false;
          // var author = "";
          // db.ref("users/" + obj.user_id + "/name").once("value", (snapshot) => {
          //   author = snapshot.val();
          //   // console.log(author);
          //   this.photos.push({
          //     title: k++ + "/" + this.N,
          //     description:
          //       "Shared by " +
          //       author +
          //       "</br></br><i class='has-size-7 th'>*ใช้เพื่อประกอบการให้ข้อมูลเพียงเท่านั้น</i>",
          //     src: obj.photo_url,
          //   });
          //   this.loading = false;
          // });
        }
        if (this.N == 0) {
          this.loading = false;

          this.photos = [];
          // this.photos.push({
          //   title: "1/1",
          //   description:
          //     "Credit: Google Map" +
          //     "</br></br><i class='has-size-7 th'>*ใช้เพื่อประกอบการให้ข้อมูลเพียงเท่านั้น</i>",
          //   src: this.getPlacePhoto(
          //     this.gmapsKey,
          //     this.condo.photos[0].photo_reference,
          //     2000
          //   ),
          // });
        }
      }
    },
  },
  methods: {
    ...mapActions(["fetchCondoPhotos"]),
    handleResize() {
      if (window.innerWidth < 800) {
        this.figure = "image is-4by3";
        this.width = "xs";
      } else if (window.innerWidth < 950) {
        this.figure = "image is-16by9";
        this.width = "sm";
      } else {
        this.figure = "image is-3by1";
        this.width = "md";
      }
    },
  },
  computed: {
    ...mapGetters(["condo", "condoPhotos", "gmapsKey"]),
    console: () => console,
  },
};
</script>
<style lang="scss">
.el-carousel__item figure {
  height: 100%;
}

.images-wrapper {
  cursor: pointer;
  background: transparent;
  transition: box-shadow 300ms ease-in-out;
}

.images-wrapper:hover {
  // box-shadow: 7px 7px 15px rgba(102, 114, 150, 0.15);
  transition: box-shadow 300ms ease-in-out;
}

.cool-lightbox-thumbs {
  background: #151515 !important;
}

.cool-lightbox__thumb:before {
  border: 3px solid #00ab6c !important;
}
</style>
