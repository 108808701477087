<template>
  <div
    v-if="review"
    class="card blend-in"
    :style="{
      backgroundImage: 'url(' + image + ')',
      height: this.cardHeight,
      width: this.cardWidth,
    }"
  >
    <!-- Edit Menu -->
    <header class="card-header px-2" style="float: right; display: block;">
      <!-- Edit dots -->
      <el-dropdown
        v-if="getLoginStatus && getUser && review.user_id == getUser.user.uid"
        trigger="click"
        class="p-2"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <i class="fa fa-ellipsis-h has-text-white"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            class="is-size-7"
            command="edit"
            icon="el-icon-edit"
          >
            Edit
          </el-dropdown-item>
          <el-dropdown-item
            class="is-size-7"
            command="delete"
            icon="el-icon-delete"
          >
            Delete
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div v-else class=""></div>
    </header>
    <!-- End Edit Menu -->

    <a
      class="card-header mt-5"
      style="display: block;"
      :href="review.review_url"
      target="_blank"
    >
      <p
        class="card-header-title is-size-6 pt-0"
        style="color: white; display: block; word-break: break-word;"
      >
        {{ review.title }}
      </p>
    </a>

    <div class="px-4 is-size-6-7 has-text-weight-semibold ellipsis">
      <a class="card-link" :href="review.review_url" target="_blank">
        <i class="fa fa-link pr-1" />
        {{
          decodeURI(
            review.review_url
              .replace("www.", "")
              .replace("https://", "")
              .replace("http://", "")
          )
        }}
      </a>
    </div>

    <a class="overlay" :href="review.review_url" target="_blank" />

    <!-- Edit Dialog -->
    <el-dialog
      v-if="getLoginStatus"
      title="Edit Post"
      :visible.sync="editDialogVisible"
      :width="width"
      center
      append-to-body
    >
      <el-form :model="form" ref="review-form">
        <el-form-item
          prop="title"
          :rules="[{ required: true, message: 'title is required' }]"
        >
          <el-input
            placeholder="What's your review title?"
            size="medium"
            v-model="form.title"
            type="text"
            maxlength="80"
            show-word-limit
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="url"
          :rules="[
            { required: true, message: 'url is required' },
            { type: 'url', message: 'Invalid URL' },
          ]"
        >
          <el-input
            placeholder="URL link to the review site (https:// website url)"
            v-model="form.url"
          >
            <template slot="prepend"><i class="fas fa-globe"></i></template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button
          v-if="form.title != '' && form.url.includes('http')"
          class="button is-primary is-fullwidth"
          @click="
            submitUpload();
            editDialogVisible = false;
          "
        >
          <div class="is-size-6-7 has-text-weight-bold">Save</div>
        </button>
        <button v-else class="button is-primary is-fullwidth" disabled>
          <div class="is-size-6-7 has-text-weight-bold">Save</div>
        </button>
      </span>
    </el-dialog>

    <!-- Confirm Dialog -->
    <el-dialog
      title="Confirm delete"
      :visible.sync="deleteDialogVisible"
      :width="width"
      center
      append-to-body
    >
      <div class="has-text-centered pt-0 pb-4">
        You are about to delete this post. Are you sure?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="
            deleteDialogVisible = false;
            deletePost();
          "
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { db, storage } from "@/firebase";
import { mapGetters } from "vuex";
export default {
  name: "CondoReviewCard",
  props: ["review", "cardHeight", "cardWidth"],
  data() {
    return {
      image: String,
      imageUrl: String,
      width: "40%",
      editDialogVisible: false,
      deleteDialogVisible: false,
      form: {
        title: this.review.title || "",
        url: this.review.review_url || "",
      },
    };
  },
  watch: {
    editDialogVisible: function() {
      this.form = {
        title: this.review.title || "",
        url: this.review.review_url || "",
      };
    },
    review: function() {
      this.setImage();
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "delete") {
        this.deleteDialogVisible = true;
      } else if (command == "edit") {
        this.editDialogVisible = true;
      }
    },
    deletePost() {
      if (this.getLoginStatus) {
        let _this = this;
        let storage_child =
          "condos/" +
          _this.review.condo_id +
          "/reviews/" +
          _this.getUser.user.uid +
          "/";

        storage
          .ref()
          .child(storage_child + _this.review.filename_thumbnail)
          .delete()
          .then(() => {
            _this.$message("Image removed");
          })
          .catch(() => {
            _this.$message(
              "Storage error occurred, please try again later. #1"
            );
          });

        storage
          .ref()
          .child(storage_child + _this.review.filename)
          .delete()
          .then(() => {
            db.ref("condo_reviews")
              .child(_this.review.condo_id + "/" + _this.review.review_id)
              .remove()
              .then(() => {
                _this.$message.success("Post deleted");
              })
              .catch(() => {
                _this.$message("Data error occurred, please try again later.");
              });
          })
          .catch(() => {
            _this.$message(
              "Storage error occurred, please try again later. #2"
            );
          });
      }
    },
    handleResize() {
      var width = window.innerWidth;
      if (width > 1200) {
        this.width = "40%";
      } else {
        this.width = Math.min(String(160 - parseInt(width / 10)), 100) + "%";
      }
    },
    submitUpload() {
      if (this.getLoginStatus) {
        this.$refs["review-form"].validate((valid) => {
          if (valid) {
            let _this = this;

            var data = {
              title: _this.form.title,
              review_url: _this.form.url,
            };
            db.ref("condo_reviews")
              .child(_this.review.condo_id + "/" + _this.review.review_id)
              .update(data, (error) => {
                if (error) {
                  _this.$message("An error occurred, please try again");
                } else {
                  _this.$message.success("Post edited");
                  _this.editDialogVisible = false;
                  _this.$refs["review-form"].resetFields();
                }
              });
          } else {
            this.$message("Invalid input, please check once again");
            return false;
          }
        });
      }
    },
    setImage() {
      this.imageUrl = this.review.photo_url;
      var that;
      var highResImage = new Image();
      that = this;
      highResImage.onload = function() {
        that.image = that.imageUrl;
      };
      highResImage.src = this.imageUrl;
    },
  },
  mounted: function() {
    this.setImage();
  },
  computed: {
    ...mapGetters(["getUser", "getLoginStatus", "fullscreen"]),
    console: () => console,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>
<style scoped>
.blend-in {
  /* https://www.epiloge.com/how-to-do-blended-in-lazy-loaded-images-in-vuejs-d4b546 */
  animation: fadein 0.15s;
  -moz-animation: fadein 0.15s; /* Firefox */
  -webkit-animation: fadein 0.15s; /* Safari and Chrome */
  -o-animation: fadein 0.15s; /* Opera */
}
</style>
