<template>
  <div class="grid-content">
    <div class="ellipsis pt-2" v-if="attribute == 'Website'">
      <p class="condo-attribute">{{ attribute }}</p>
      <span class="condo-value-icon"><i :class="icon"></i></span>
      <a
        :href="value"
        target="_blank"
        v-if="value"
        class="condo-value-link ellipsis"
        >{{ value }}</a
      >
    </div>

    <div v-else class="ellipsis-hidden">
      <p class="condo-attribute">{{ attribute }}</p>
      <span class="condo-value-icon"><i :class="icon"></i></span>
      <span class="condo-value">{{ value }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "CondoAttribute",
  props: ["attribute", "value", "icon"],
};
</script>
<style scoped>
.grid-content {
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  min-height: 36px;
  background: #fff;
}

.condo-attribute {
  font-weight: 500;
  font-size: 0.7rem;
  color: #7e97ad;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.condo-value {
  font-weight: 700;
  font-size: 0.8rem;
}

.condo-value-link {
  font-weight: 500;
  font-size: 0.7rem;
  color: #00ab6c;
}

.condo-value-icon {
  padding-right: 10px;
  color: rgb(215 222 232);
  font-size: 0.7rem;
}
</style>
