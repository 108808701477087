var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{},[_c('el-col',{attrs:{"lg":24}},[_c('nav',{staticClass:"level mx-2 mb-4 is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"title is-5"},[_vm._v("Posts & Reviews")])]),_c('div',{staticClass:"level-right is-size-6-7"},[_c('a',{on:{"click":function($event){return _vm.updateDialogVisible({ type: 'review', isVisible: true })}}},[_vm._v("see more ")])])]),_c('div',{staticClass:"scrolling-wrapper-review"},[_c('div',{staticClass:"review-button",on:{"click":function($event){_vm.postReviewDialogVisible = true}}},[_c('i',{staticClass:"fas fa-plus"}),_c('div',{staticClass:"button-text"},[_vm._v(" Post ")])]),_vm._l((_vm.reviews),function(review,index){return _c('CondoReviewCard',{key:index,attrs:{"review":review,"cardWidth":"202px","cardHeight":"173px"}})}),(_vm.reviews.length > 0)?_c('div',{staticClass:"card review-blank",style:({
          width: '250px',
          height: '173px',
        })},[_c('a',{staticClass:"card-header mt-5",staticStyle:{"display":"block"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"card-header-title is-size-6 py-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" Help the community ")]),_c('p',{staticClass:"card-header-title is-size-6-7 has-text-weight-medium pt-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" We're looking for some blog posts. ")])])]):_vm._e(),(_vm.reviews.length == 0)?_c('div',{staticClass:"card review-blank",style:({
          width: '250px',
          height: '173px',
        })},[_c('a',{staticClass:"card-header mt-5",staticStyle:{"display":"block"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"card-header-title is-size-6 py-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" No Posts Yet ")]),_c('p',{staticClass:"card-header-title is-size-6-7 has-text-weight-medium pt-0",staticStyle:{"color":"#566e82","display":"block","word-break":"break-word"}},[_vm._v(" We're looking for some blog posts. ")])])]):_vm._e()],2),(_vm.getLoginStatus)?_c('el-dialog',{attrs:{"title":"Post Property Content","visible":_vm.postReviewDialogVisible,"width":_vm.screenWidth,"top":"15vh","center":""},on:{"update:visible":function($event){_vm.postReviewDialogVisible=$event}}},[_c('el-form',{ref:"review-form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"title","rules":[{ required: true, message: 'title is required' }]}},[_c('el-input',{attrs:{"placeholder":"What's your review title?","size":"medium","type":"text","maxlength":"80","show-word-limit":"","autocomplete":"off"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"prop":"url","rules":[
            { required: true, message: 'url is required' },
            { type: 'url', message: 'Invalid URL' } ]}},[_c('el-input',{attrs:{"placeholder":"URL link to the review site (https:// website url)"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"fas fa-globe"})])],2)],1)],1),_c('el-upload',{ref:"review-upload",staticClass:"review-upload",attrs:{"action":"","drag":"","list-type":"picture-card","file-list":_vm.fileList,"http-request":_vm.firebaseUpload,"auto-upload":false,"on-change":_vm.onChange}},[_c('i',{staticClass:"el-icon-upload is-size-3 has-text-info my-2"}),_c('div',{staticClass:"el-upload__text"},[_vm._v(" Drop an image here or "),_c('em',[_vm._v("click to upload")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" jpg/png file with a size less than 3Mb ")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(
            _vm.fileList.length == 1 &&
              _vm.form.title != '' &&
              _vm.form.url.includes('http')
          )?_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){_vm.submitUpload();
            _vm.postReviewDialogVisible = false;}}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])]):_c('button',{staticClass:"button is-primary is-fullwidth",attrs:{"disabled":""}},[_c('div',{staticClass:"is-size-6-7 has-text-weight-bold"},[_vm._v("Post")])])])],1):_vm._e(),_c('LoginDialog',{attrs:{"dialogVisible":_vm.postReviewDialogVisible},on:{"disable-loginDialog":function($event){_vm.postReviewDialogVisible = false}}}),_c('CondoReviewsDialog',{attrs:{"reviewsDialogVisible":_vm.reviewsDialogVisible},on:{"disable-reviewDialog":function($event){return _vm.updateDialogVisible({ type: 'review', isVisible: false })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }